import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    APIError,
    ConfigState,
    GetCurrentConfigProps,
    QuizQuestionDTO,
    TimelineConfig,
    StateStatus,
    Config,
} from "../../types";
import { MediaItemDTO } from "../../types/apis/models/mediaItem.types";
import {
    getAvailableGameVersions,
    getCurrentConfig,
} from "../api-requests/configs";
import { getMediaItemsOptions } from "../api-requests/mediaItems";
import { getQuizzes } from "../api-requests/quiz";

/**
 * The initial state of the slice
 */
const initialState: ConfigState = {
    status: StateStatus.idle,
    error: null,
    currentConfig: null,
    triedFetching: false,
    availableGameVersions: null,
    currentQuizzes: null,
    currentMediaItems: null,
};

/**
 * Fetches the current config for the FutureX demo from the tour-api
 * Throws an error when the response is empty or an error has been encountered during fetch
 *
 * @returns ConfigDTO OR APIError
 */
export const fetchCurrentConfig = createAsyncThunk<
    // Return type of the payload creator
    { config: TimelineConfig | null; quizzes: QuizQuestionDTO[], mediaItems: MediaItemDTO[] },
    // First argument to the payload creator
    GetCurrentConfigProps,
    // Types for ThunkAPI
    {
        rejectValue: APIError<string>;
    }
>("configs/current", async (props, thunkAPI) => {
    try {
        const configDTO = await getCurrentConfig(props);
        if (configDTO) {
            const config = JSON.parse(configDTO.configJson) as Config;
            const timelineConfig = config?.timelineConfig;
            let quizzes: QuizQuestionDTO[] = [];
            let mediaItems: MediaItemDTO[] = [];
            if(!timelineConfig){
                return { config: null, quizzes, mediaItems };
            }
            if (timelineConfig.Quizzes?.length > 0) {
                const quizIds = timelineConfig.Quizzes.map((quiz) => quiz.QuestionID);
                // Get all questions before hand to reduce delay during the quiz itself
                try {
                    quizzes = (await getQuizzes(quizIds)) || [];
                } catch (error) {
                    console.error(error);
                }
            }

            if (timelineConfig.UserAppFeedbacks?.length > 0){
                const mediaItemIds = timelineConfig.UserAppFeedbacks.map((userAppFeedback) => userAppFeedback.MediaItemID);
                //Get all mediaitems before hand
                try {
                    mediaItems = (await getMediaItemsOptions({ByIds: mediaItemIds, SetHostname:`visitor.livingtomorrow.com`})) || [];
                } catch (error) {
                    console.error(error);
                }
            }
            return { config: timelineConfig, quizzes, mediaItems };
        } else {
            return thunkAPI.rejectWithValue({
                Errors: "Didn't receive a config",
            } as APIError<string>);
        }
    } catch (err) {
        return thunkAPI.rejectWithValue({
            Errors: (err as Error).message,
        } as APIError<string>);
    }
});

/**
 * Gets all the available gameversions for a specific demo
 * Throws an error when the response is empty or an error has been encountered during fetch/post
 *
 * @returns ConfigDTO OR APIError
 */
export const fetchAvailableGameVersions = createAsyncThunk<
    // Return type of the payload creator
    string[],
    // First argument to the payload creator
    string,
    // Types for ThunkAPI
    {
        rejectValue: APIError<string>;
    }
>("configsTemplates/gameversions", async (demoId, thunkAPI) => {
    try {
        const gameVersions = await getAvailableGameVersions(demoId);
        if (gameVersions) {
            return gameVersions;
        } else {
            return thunkAPI.rejectWithValue({
                Errors: "Didn't receive a gameVersion",
            } as APIError<string>);
        }
    } catch (err) {
        return thunkAPI.rejectWithValue({
            Errors: (err as Error).message,
        } as APIError<string>);
    }
});

export const configSlice = createSlice({
    name: "configs",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        resetConfigs: (state) => {
            state.status = StateStatus.idle;
            state.error = null;
            state.currentConfig = null;
            state.triedFetching = false;
            state.availableGameVersions = null;
        },
    },
    extraReducers: (builder) => {
        // Fetch Current Config
        builder.addCase(fetchCurrentConfig.pending, (state) => {
            state.status = StateStatus.fetching;
            state.error = null;
        });
        builder.addCase(fetchCurrentConfig.fulfilled, (state, { payload }) => {
            state.status = StateStatus.idle;
            state.triedFetching = true;
            state.error = null;
            state.currentConfig = payload.config;
            state.currentQuizzes = payload.quizzes;
            state.currentMediaItems = payload.mediaItems;
        });
        builder.addCase(fetchCurrentConfig.rejected, (state, { payload }) => {
            state.status = StateStatus.error;
            state.triedFetching = true;
            if (payload) {
                state.error = payload as APIError<string>;
            }
        });

        // Get Available GameVersions
        builder.addCase(fetchAvailableGameVersions.pending, (state) => {
            state.status = StateStatus.fetching;
            state.error = null;
        });
        builder.addCase(
            fetchAvailableGameVersions.fulfilled,
            (state, { payload }) => {
                state.status = StateStatus.idle;
                state.error = null;
                state.availableGameVersions = payload;
            }
        );
        builder.addCase(
            fetchAvailableGameVersions.rejected,
            (state, { payload }) => {
                state.status = StateStatus.error;
                if (payload) {
                    state.error = payload as APIError<string>;
                }
            }
        );
    },
});

export const { resetConfigs } = configSlice.actions;

export default configSlice.reducer;
