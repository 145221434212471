import { FC, memo } from "react";
import EarthFromSpace from "./EarthFromSpace";
import "./css/EarthFooter.css";

interface Props{
    className?:string;
}

const EarthFooter: FC<Props> = ({className}) => {
    return (<EarthFromSpace className={`earthFooter ${className}`}/>)
}

export default memo(EarthFooter);