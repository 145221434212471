import { MediaItemDTO } from "../../types/apis/models/mediaItem.types";
import { fetchSimple, getBaseUrl } from "../api.utils";

export interface MediaItemFilterModel{
    ByName?: string;
    ByIds?: string[];
    WithTags?: MediaItemTags;
    ByType?: string[];
    SetHostname?: string;
}

export interface MediaItemTags{
    tags?: string[];
    Operator?: string;
}



export const getMediaItem = async (
    mediaItemId: string
): Promise<MediaItemDTO | null> => {
    const baseConfigTemplatesURL = await getBaseUrl("mediaitems");
    return await fetchSimple<MediaItemDTO>(
        `${baseConfigTemplatesURL}/${mediaItemId}/get`
    );
};

export const getMediaItemsOptions = async (
    filters: MediaItemFilterModel
): Promise<MediaItemDTO[] | null> => {
    const baseConfigTemplatesURL = await getBaseUrl("mediaitems");
    return await fetchSimple<MediaItemDTO[]>(
        `${baseConfigTemplatesURL}`
    , { method: "POST", body: JSON.stringify(filters) });
};