import { FC, memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCookie } from "react-use";
import LTLogoFooter from "../components/common/LTLogo/LTLogoFooter";
import { useAppSelector } from "../store";
import "./css/ErrorPage.css";

interface Props {
    readonly children?: JSX.Element;
    readonly errorToLog?: (string | object)[];
    readonly errorText?: string;
}

const ErrorPage: FC<Props> = ({ children, errorToLog, errorText }) => {
    const { user, triedLoggingIn } = useAppSelector((state) => state.auth);
    const [token, ,] = useCookie("jwt-token");
    const navigate = useNavigate();

    useEffect(() => {
        errorToLog && console.log(...errorToLog);
    }, [errorToLog]);

    useEffect(() => {
        if (user === null && token && triedLoggingIn) {
            navigate("/register");
        }
    }, [navigate, token, triedLoggingIn, user]);

    return (
        <div className="errorPage">
            <h3>
                {errorText || "Something went wrong..."}
            </h3>
            {children}
            <LTLogoFooter/>
        </div>
    );
};

export default memo(ErrorPage);
