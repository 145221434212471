import { QuizChosenAnswerPostDTO, QuizQuestionDTO, QuizResponseCountDTO, QuizScoreOverviewDTO, QuizUserScoreDTO } from "../../types";
import { getBaseUrl, fetchRefreshIntegrated } from "../api.utils";

export const getQuizzes = async (
    quizIds: string[]
): Promise<QuizQuestionDTO[] | null> => {
    const baseConfigTemplatesURL = await getBaseUrl("quizQuestions");
    const queryString = quizIds.reduce((prevValue, curValue) => {
        return `${prevValue}&idList=${curValue}`;
    }, quizIds.shift());
    return await fetchRefreshIntegrated<QuizQuestionDTO[]>(
        `${baseConfigTemplatesURL}/GetMultiple?idList=${queryString}`
    );
};

export const getScoreOverview = async (
    groupId: string, questionId: string
): Promise<QuizScoreOverviewDTO | null> => {
    const baseConfigTemplatesURL = await getBaseUrl("quizScores");
    return await fetchRefreshIntegrated<QuizScoreOverviewDTO>(
        `${baseConfigTemplatesURL}/${groupId}/${questionId}`
    );
};

export const getScoreOverviewTake = async (
    groupId: string, questionId: string, take: number
): Promise<QuizScoreOverviewDTO | null> => {
    const baseConfigTemplatesURL = await getBaseUrl("quizScores");
    return await fetchRefreshIntegrated<QuizScoreOverviewDTO>(
        `${baseConfigTemplatesURL}/${groupId}/${questionId}/${take}`
    ).catch((err) => {
        console.error(err);
        return null;
    });
};

export const getCurrentScores = async (
    groupId: string
): Promise<QuizUserScoreDTO[] | null> => {
    const baseConfigTemplatesURL = await getBaseUrl("quizScores");
    return await fetchRefreshIntegrated<QuizUserScoreDTO[]>(
        `${baseConfigTemplatesURL}/${groupId}`
    );
};

export const postChosenAnswer = async (chosenAnswer: QuizChosenAnswerPostDTO): Promise<void> => {
    const baseConfigTemplatesURL = await getBaseUrl("quizChosenAnswers");
    await fetchRefreshIntegrated<void>(`${baseConfigTemplatesURL}`, { method: "POST", body: JSON.stringify(chosenAnswer) });
};

export const getResponseOverview = async (groupId: string, questionId: string): Promise<QuizResponseCountDTO[]> => {
    const baseUrl = await getBaseUrl("quizChosenAnswers");
    return await fetchRefreshIntegrated<QuizResponseCountDTO[]>(
        `${baseUrl}/responseOverview/${groupId}/${questionId}`
    );
};
