import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { getLocalizedText } from "../../helpers/localizationHelper";
import { useAppDispatch, useAppSelector } from "../../store";
import { setUserAvatar } from "../../store/authentication/authenticationSlice";
import { fetchAvatarsByGroup } from "../../store/groups/groupsSlice";
import { AvatarAvailabilityDTO } from "../../types/apis/models/avatars.types";
import EarthFooter from "../common/Illustrations/EarthFooter";
import LTButton from "../common/UI-Elements/LTButton";
import RoundPictureFrame from "../common/UI-Elements/RoundPictureFrame";
import "./css/ChooseAvatars.css";

interface Props {}

const ChooseAvatars: FC<Props> = () => {
    const { user } = useAppSelector((state) => state.auth);
    const [chosenAvatar, setChosenAvatar] = useState<AvatarAvailabilityDTO | null>(null);
    const { avatars } = useAppSelector((state) => state.groups);
    const { dictionary } = useAppSelector((state) => state.localization);
    const dispatch = useAppDispatch();

    const scrollToElement = (id:number) => {
        setTimeout(() => {
            document.getElementById(`avatar${id}`)?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            });
        },400)
    }

    const handleClickAvatar = useCallback(async (avatar: AvatarAvailabilityDTO | null) => {
        if (user?.groupId != null) {
            await dispatch(fetchAvatarsByGroup(user?.groupId));
        }
        if(avatar?.id && avatar.available){
            setChosenAvatar(avatar);
        }
    },[dispatch, user?.groupId]);

    const avatarVisualization = useMemo(
        () =>{
            return(
                <div className="chooseAvatars">
                <h1>{getLocalizedText(dictionary,'chooseyouravatar',user?.locale?.id)}</h1>
                <div className="avatarCloud">
                    {avatars.filter(x => x.available).map((value, index) => {

                        return <div
                                id={`avatar${index}`}
                                style={{animation: `warpFromCenter ${(0+index/10)/2.5+0.5}s`,
                                paddingTop:`${index%2*15}px`}}
                                key={index}
                                onClick={() => {handleClickAvatar(value);scrollToElement(index)}}>
                                    <RoundPictureFrame 
                                    className="avatarButton"
                                    style={{animation:`inSpace ${(index%2)/2+20+2}s infinite linear ${index%2===0 ? 'alternate' : 'reverse'}`}}
                                    src={value.uri} 
                                    size={value.id !== chosenAvatar?.id ?
                                        `${(Math.sin(0.002*index)+Math.sin(2*index)+Math.sin(0.4*index))+5
                                        }em` : "50vw"}
                                    backgroundColor={`${value.id === chosenAvatar?.id ? "var(--primary-main)":"transparent"}`}/>
                            </div>
                    })}

                </div>
            </div>
            )},
        [avatars, chosenAvatar, dictionary, handleClickAvatar, user?.locale?.id]
    );

    const handleChooseAvatar = useCallback(async () => {
        if(user?.groupId){
            await dispatch(fetchAvatarsByGroup(user.groupId));
        }
        if (chosenAvatar?.id) {
            await dispatch(setUserAvatar(chosenAvatar.id));
        }
    }, [chosenAvatar, dispatch, user?.groupId]);

    useEffect(() => {
        if (user?.groupId != null) {
            dispatch(fetchAvatarsByGroup(user?.groupId));
        }
    }, [dispatch, user?.groupId]);

    const handleClickContainer = useCallback(() => {
        setChosenAvatar(null);
    },[]);

    return (
        <div onClick={handleClickContainer}>
            {avatarVisualization}
            {chosenAvatar && avatars.find(x => x.id === chosenAvatar.id)?.available ? <div className="avatarFooter">
                <LTButton className="formField formText" onClick={handleChooseAvatar}>{getLocalizedText(dictionary,"continue",user?.locale?.id)}</LTButton>
            </div> : null}
            <EarthFooter className="flyOutAnimationBottom avatarEarthFooter"/>
        </div>
    );
};

export default memo(ChooseAvatars);
