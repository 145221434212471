import { CSSProperties, FC, memo } from "react";
import "./css/RoundPictureFrame.css"
interface Props{
    src?: string;
    alt?: string;
    size?: string;
    ringCount?: number;
    ringColor?: string;
    imageBorderColor?: string;
    ringShrinkPercentage?: string;
    borderWidth?: string;
    className?: string;
    backgroundColor?: string;
    style?:CSSProperties;
}

const RoundPictureFrame: FC<Props> = ({src,alt = "", size = "20em", ringCount = 1, imageBorderColor = "white", ringColor = "white", ringShrinkPercentage = "75%", borderWidth = "1px", className = "", backgroundColor="transparent",style}) => {

    

    const ringNester = (maxIteration:number,iteration:number = 0) => {
        if(iteration < maxIteration){
            iteration++;
            return(
                <div className="roundedPictureFrame roundImage" style={{width:`${ringShrinkPercentage}`, height: `${ringShrinkPercentage}`, borderColor:`${iteration === maxIteration ? imageBorderColor : ringColor}`, borderWidth:`${borderWidth}`}}>
                    {ringNester(maxIteration, iteration)}
                </div>
            );
        }
        else{
            return(
                    <img src={src} alt={alt} width="100%" height="100%" className="roundImage" style={{backgroundColor:backgroundColor}}/>
            )
        } 
    }

    return (
        <div className={`roundedPictureFrame roundImage ${className}`} style={{width:`${size}`, height:`${size}`, borderColor:`${ringCount > 0 ? ringColor : imageBorderColor}`, borderWidth:`${borderWidth}`,...style}}>
            {ringNester(ringCount)}
        </div>
    );
}

export default memo(RoundPictureFrame);