import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { BasicLoggerParam, Logger, LoggerLogObject, LoggerProviderProps, TEXT_FORMAT_STRING, TITLE_FORMAT_STRING } from "../types";

/**
 * Defines a structure for this context
 */
const LoggerContext = createContext<Logger>({
	log: () => {},
	warn: () => {}
});

export const LoggerProvider = ({ clearAfter = 10, children }: LoggerProviderProps) => {
	const [, setLogCount] = useState(0);

	/**
	 * Uses the clearAfter number to clear the console after a certain amount of logs
	 * Default to clearing after 10 logs
	 *
	 * This is to prevent excessive use of ram in the browser window
	 */
	const clearCheckBefore = useCallback(
		// Uses a callback function to execute after the clear check has been done
		(next: (...args: any) => any) => {
			setLogCount((prevCount) => {
				if (++prevCount >= clearAfter) {
					console.clear();
					return 0;
				}

				return prevCount;
			});
			next();
		},
		[clearAfter]
	);

	/**
	 * Creates a formatted log to the console using the provided LoggerLogObject
	 */
	const log = useCallback((param: LoggerLogObject) => {
		// Start from empty string and empty output params
		// Console.log takes a string (text) and extra params (objects/strings that are referenced in the output string)
		let outputString = "";
		let outputParams: any = [];

		// If there is a title, add it with %c prefix to format this bit
		// Adds the format string for title to the output params
		if (param.title) {
			outputString += `%c${param.title}`;
			outputParams.push(TITLE_FORMAT_STRING);
		}

		// If there is text, add it with %c prefix to format this bit
		// Adds the format string for text to the output params
		if (param.text) {
			outputString += `${outputString === "" ? "" : "\n"}%c${param.text}`;
			outputParams.push(TEXT_FORMAT_STRING);
		}

		// If there is a an object to log, add it with %o
		// If the current outputString is empty, just put '%o'
		// If there is already something in the outputstring, make sure the object gets displayed on the next line
		if (param.objectToLog) {
			outputString += `${outputString === "" ? "" : "\n"}%o`;
			outputParams.push(param.objectToLog);
		}

		console.log(outputString, ...outputParams);
	}, []);

	/**
	 * Logs the provided string/object to the console using console.warn()
	 */
	const warn = useCallback((param: BasicLoggerParam) => console.warn(param), []);

	/**
	 * Prepare the logger value for the provider of the context
	 */
	const logger = useMemo<Logger>(
		() => ({
			log: (param: LoggerLogObject) => clearCheckBefore(() => log(param)),
			warn: (param: BasicLoggerParam) => clearCheckBefore(() => warn(param))
		}),
		[log, warn, clearCheckBefore]
	);

	return <LoggerContext.Provider value={logger}>{children}</LoggerContext.Provider>;
};

export const useLogger = () => useContext(LoggerContext);
