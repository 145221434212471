import { FC, memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCookie } from "react-use";
import LTLogoFooter from "../components/common/LTLogo/LTLogoFooter";
import { getLocalizedText } from "../helpers/localizationHelper";
import { useAppSelector } from "../store";
import { TimelineStatus } from "../store/signalr/signalr.types";
import "./css/EndTourPage.css";

interface Props {
}

const EndTourPage: FC<Props> = () => {
    const {user, triedLoggingIn} = useAppSelector((state) => state.auth);
    const { timelineStatus } = useAppSelector((state) => state.signalr);
    const { dictionary, defaultLocale } = useAppSelector((state) => state.localization);
    const [token,,deleteToken] = useCookie('jwt-token');
    const navigate = useNavigate();

    useEffect(() => {
        if(user === null && token && triedLoggingIn){
            deleteToken();
        }
        else if (user !== null && token && triedLoggingIn && timelineStatus !== TimelineStatus.EndTour){
            navigate("/room");
        }
        else if(!token && triedLoggingIn){
            navigate("/register");
        }
    },[deleteToken, navigate, timelineStatus, token, triedLoggingIn, user])

    return (
       <div className="endTour">
        <h3>
           {getLocalizedText(dictionary, 'tourended', defaultLocale.id)}
        </h3>
        <LTLogoFooter/>
       </div>        
    );
};

export default memo(EndTourPage);