import { ReactNode } from "react";

export type BasicLoggerParam = string | object;

export type LoggerLogObject = {
	readonly text?: string;
	readonly title?: string;
	readonly objectToLog?: object;
};

export type LoggerLog = (param: LoggerLogObject) => void;
export type LoggerWarn = (param: BasicLoggerParam) => void;

export type Logger = {
	log: LoggerLog;
	warn: LoggerWarn;
};

export interface LoggerProviderProps {
	readonly children?: ReactNode;
	readonly clearAfter?: number;
}

export const TITLE_FORMAT_STRING = "font-size:15px;color:rgb(36,63,114);font-family:'Open Sans'; font-weight:600;";

export const TEXT_FORMAT_STRING = "font-size:15px;color:rgb(36,63,114);font-family:'Open Sans';font-weight:100;";
