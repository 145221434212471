import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { APIError, APIResponse, GroupDTO, StateStatus } from "../../types";
import { AvatarAvailabilityDTO } from "../../types/apis/models/avatars.types";
import { getAvailableAvatarsByGroup } from "../api-requests/avatars";
import { getCurrentGroup, getGroupExistsById } from "../api-requests/groups";
import { getCorrectErrorMessage } from "../api.utils";
import { AuthenticationError } from "../authentication/authentication.types";
import { GroupsState } from "./groups.types";





/**
 *	Checks if a group exists
 *
 *	@returns APIResponse<boolean> OR APIError
 */
export const checkIfGroupExistsById = createAsyncThunk<
    // Return type of the payload creator
    APIResponse<Boolean>,
    // First argument to the payload creator
    string,
    // Types for ThunkAPI
    {
        rejectValue: APIError<string>;
    }
>("groups/exists", async (id, thunkAPI) => {
    try {
        const { exists } = await getGroupExistsById(id);

        return { response: exists };
    } catch (err) {
        let rejectValue: APIError<string> = {
            Errors: getCorrectErrorMessage<AuthenticationError>(err as Error),
        };

        return thunkAPI.rejectWithValue(rejectValue);
    }
});

/**
 *	Gets the current group of a user
 *
 *	@returns APIResponse<AvatarAvailabilityDTO[]> OR APIError
 */
export const fetchCurrentGroup = createAsyncThunk<
    // Return type of the payload creator
    APIResponse<GroupDTO>,
    // First argument to the payload creator
    string,
    // Types for ThunkAPI
    {
        rejectValue: APIError<string>;
    }
>("groups/currentGroup", async (groupId, thunkAPI) => {
    try {
        const group = await getCurrentGroup(groupId);

        return { response: group };
    } catch (err) {
        let rejectValue: APIError<string> = {
            Errors: getCorrectErrorMessage<AuthenticationError>(err as Error),
        };

        return thunkAPI.rejectWithValue(rejectValue);
    }
});

/**
 *	Get avatars for a certain group
 *
 *	@returns APIResponse<AvatarAvailabilityDTO[]> OR APIError
 */
export const fetchAvatarsByGroup = createAsyncThunk<
    // Return type of the payload creator
    APIResponse<AvatarAvailabilityDTO[]>,
    // First argument to the payload creator
    string,
    // Types for ThunkAPI
    {
        rejectValue: APIError<string>;
    }
>("groups/avatars", async (groupId, thunkAPI) => {
    try {
        const avatars = await getAvailableAvatarsByGroup(groupId);

        return { response: avatars };
    } catch (err) {
        let rejectValue: APIError<string> = {
            Errors: getCorrectErrorMessage<AuthenticationError>(err as Error),
        };

        return thunkAPI.rejectWithValue(rejectValue);
    }
});

const initialState: GroupsState = {
    status: StateStatus.idle,
    hasChecked: false,
    groupExists: false,
    error: null,
    avatars: [],
    currentGroup: null,
    triedFetchingGroup: false,
    demoId: null
};

export const groupsSlice = createSlice({
    name: "groups",
    initialState,
    reducers: {
        setdemoId: (state, action: PayloadAction<string>) => {
            state.demoId = action.payload;
        }
    },
    extraReducers: (builder) => {
        // Check if group exists by id
        builder.addCase(checkIfGroupExistsById.pending, (state, action) => {
            state.status = StateStatus.fetching;
            state.error = null;
            state.groupExists = false;
        });
        builder.addCase(checkIfGroupExistsById.fulfilled, (state, action) => {
            state.status = StateStatus.idle;
            state.groupExists = true;
            state.hasChecked = true;
        });
        builder.addCase(checkIfGroupExistsById.rejected, (state, action) => {
            state.status = StateStatus.error;
            state.hasChecked = true;
            state.groupExists = false;
            state.error = action.payload || null;
        });

        // Get avatars by group id
        builder.addCase(fetchAvatarsByGroup.pending, (state, action) => {
            state.status = StateStatus.fetching;
            state.error = null;
        });
        builder.addCase(fetchAvatarsByGroup.fulfilled, (state, action) => {
            state.status = StateStatus.idle;
            state.avatars = [...action.payload.response]
        });
        builder.addCase(fetchAvatarsByGroup.rejected, (state, action) => {
            state.status = StateStatus.error;
            state.avatars = [];
            state.error = action.payload || null;
        });

        // Get current group based on JWT token
        builder.addCase(fetchCurrentGroup.pending, (state, action) => {
            state.status = StateStatus.fetching;
            state.error = null;
            state.triedFetchingGroup = true;
        });
        builder.addCase(fetchCurrentGroup.fulfilled, (state, action) => {
            state.status = StateStatus.idle;
            state.currentGroup = { ...action.payload.response };
        });
        builder.addCase(fetchCurrentGroup.rejected, (state, action) => {
            state.status = StateStatus.error;
            state.error = action.payload || null;
        });
    },
});

export const { setdemoId } = groupsSlice.actions;

export default groupsSlice.reducer;
