import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import authenticationReducer from "./authentication/authenticationSlice";
import groupsReducer from "./groups/groupsSlice";
import configsReducer from "./configs/configSlice";
import signalRReducer from "./signalr/signalrSlice";
import localizationSlice from "./localization/localizationSlice";

export const store = configureStore({
    reducer: {
        auth: authenticationReducer,
        groups: groupsReducer,
        configs: configsReducer,
        signalr: signalRReducer,
        localization: localizationSlice
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
