import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { APIError, APIResponse, StateStatus } from "../../types";
import { LocalizationState } from "./localization.types";
import { Locale } from "../../types/apis/models/locale.types";
import { getAllLocales } from "../api-requests/locales";
import { getCorrectErrorMessage } from "../api.utils";
import { AuthenticationError } from "../authentication/authentication.types";

export const fetchTranslationOverride = createAsyncThunk('locales/fetchDictionary', async (_,thunkAPI) => {
    
    try {
        var response = await fetch(`${window.location.origin}/translation.json`);
        var dictionary = await response.json();
        return dictionary;
    } catch (error) {
        let rejectValue: APIError<string> = {
            Errors: getCorrectErrorMessage<AuthenticationError>(error as Error),
        };

        return thunkAPI.rejectWithValue(rejectValue);
    }
});

export const fetchLocales = createAsyncThunk<
    // Return type of the payload creator
    APIResponse<Locale[]>,
    // First argument to the payload creator
    undefined,
    // Types for ThunkAPI
    {
        rejectValue: APIError<string>;
    }
>("locales/fetchLocales", async (_, thunkAPI) => {
    try {
        const locales = await getAllLocales();

        return { response: locales };
    } catch (err) {
        let rejectValue: APIError<string> = {
            Errors: getCorrectErrorMessage<AuthenticationError>(err as Error),
        };

        return thunkAPI.rejectWithValue(rejectValue);
    }
});


const initialState = (): LocalizationState => {
    const standardLanguages = ["en","fr","nl"]
    const browserLanguages = navigator.languages;
    const search = browserLanguages.find(x => standardLanguages.includes(x));
    let tempDefaultLocale : Locale = {id:"en", name:"English"};
    if(search){
        tempDefaultLocale = {id:search};
    }
    return {
        locales: [],
        defaultLocale: tempDefaultLocale,
        dictionary: null,
        status: StateStatus.idle,
        triedFetching: false,
        error: null
    }
}
export const localizationSlice = createSlice({
	name: "localization",
	initialState,
	reducers: {
        setDefaultLocale: (state, { payload }) => {
            state.defaultLocale = {...payload}
        }
	},
    extraReducers: (builder) => {
        builder.addCase(fetchLocales.pending, (state) => {
            state.status = StateStatus.fetching;
            state.error = null;
        });
        builder.addCase(fetchLocales.fulfilled, (state, { payload }) => {
            state.status = StateStatus.idle;
            state.triedFetching = true;
            state.error = null;
            state.locales = [...payload.response];
        });
        builder.addCase(fetchLocales.rejected, (state, { payload }) => {
            state.status = StateStatus.error;
            state.triedFetching = true;
            if (payload) {
                state.error = payload as APIError<string>;
            }
        });
        builder.addCase(fetchTranslationOverride.pending, (state) => {
            state.status = StateStatus.fetching;
            state.error = null
        });
        builder.addCase(fetchTranslationOverride.fulfilled, (state,{ payload }) => {
            state.status = StateStatus.idle;
            state.triedFetching = true;
            state.error = null
            state.dictionary = {...payload};
        });
        builder.addCase(fetchTranslationOverride.rejected, (state, { payload }) => {
            state.status = StateStatus.error;
            state.triedFetching = true;
            if (payload) {
                state.error = payload as APIError<string>;
            }
        })
    }
});

export const { setDefaultLocale } = localizationSlice.actions;

export default localizationSlice.reducer;