import { Field, Form, Formik, FormikErrors } from "formik";
import { FC, memo, useCallback, useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { CircularProgress } from "@mui/material";
import { getLocalizedText } from "../../helpers/localizationHelper";
import { useAppSelector } from "../../store";
import { getGroupExistsById } from "../../store/api-requests/groups";
import { useNavigate } from "react-router-dom";
import { throttle } from "lodash";
import LTButton from "../common/UI-Elements/LTButton";
import "./css/CodeRegistration.css";
import LTLogo from "../common/LTLogo/LTLogo";
import EarthFooter from "../common/Illustrations/EarthFooter";

interface Props {
}

interface CodeRegistrationModel {
    code?: string | null;
}

const formValues: CodeRegistrationModel = {
    code: ""
}
const CodeRegistration: FC<Props> = () => {
    const { dictionary, defaultLocale } = useAppSelector(state => state.localization);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const navigate = useNavigate();
    const isMounted = useRef<boolean>(false);

    useEffect(() => {
        //on mount
        isMounted.current = true;
        //unmount
        return(() => {
            isMounted.current = false;
        });
    },[]);

    const validationSchema = yup.object({
        code: yup
            .string()
            .min(4, getLocalizedText(dictionary, 'invalidcode', defaultLocale?.id))
            .required(getLocalizedText(dictionary, 'invalidcode', defaultLocale?.id))
    });

    const throttledOnSubmit = useRef(throttle(async (code:string, setSubmitting: (isSubmitting:boolean) => void, setErrors: (errors: FormikErrors<any>) => void ) => {
        await handleOnSubmit(code, setSubmitting, setErrors);
    },2000));

    const handleOnSubmit = async (code:string, setSubmitting: (isSubmitting:boolean) => void, setErrors: (errors: FormikErrors<any>) => void ) => {
        setSubmitting(true);
        code = code.trim().replace(/ /g,'').toUpperCase();
        if (code !== null && code !== undefined && code !== "") {
            let codeExists = await checkGroupCodeExists(code);
            if (codeExists === true) {
                navigate(`/register/${code}/choose-nickname/1`);
            }
            else {
                setErrors({ code: `${getLocalizedText(dictionary, 'invalidcode', defaultLocale?.id)}` });
            }
        }
        setSubmitting(false);
    }

    const checkGroupCodeExists = useCallback(async (code: string): Promise<boolean> => {
        let result = false
        try {
            if(isMounted.current){
                setIsFetching(true);
            }
            let response = await getGroupExistsById(code);
            result = response.exists;
        } catch (error) {
        }
        if(isMounted.current){
            setIsFetching(false);
        }
        return result;
    }, [])

    return <><div className="codeRegistration">
        <Formik<CodeRegistrationModel>
            initialValues={formValues} validationSchema={validationSchema} onSubmit={async ({ code }, { setSubmitting, setErrors }) => {
                if(!code){
                    return;
                }
                await throttledOnSubmit.current(code, setSubmitting, setErrors);
            }}>
            {
                ({ isSubmitting, touched, errors }) => (
                    <Form className="codeRegistrationForm">
                        <div className="top flyInAnimationTop">
                            <LTLogo/>
                            <div>
                                <p className="labelText"><i className="fa fa-solid fa-key ltThin"></i>&nbsp;{getLocalizedText(dictionary,"registrationcode",defaultLocale.id)}</p>
                                <Field id="code" name="code" type="text" className="formField formText codeText" autoComplete="off" spellCheck="false" placeholder="Code"/>
                                <p className="formWarning textAlignLeft">{(touched.code && errors.code) || <br />}</p>
                            </div>
                        </div>

                        <div className="bottom flyInAnimationBottom">
                            <LTButton type="submit" disabled={isFetching || isSubmitting } className="formField formText">
                            <div className="labelText"><i className="fa fa-solid fa-user ltThin lightBackground"></i>+&nbsp; {getLocalizedText(dictionary, 'register', defaultLocale?.id)}</div>
                            {isFetching ? <CircularProgress size="1em"/> : null}
                            </LTButton>
                        </div>
                    </Form> 
                )
            }
        </Formik>
          
    </div>
    <EarthFooter className="flyInAnimationBottom"/> 
    </>
}

export default memo(CodeRegistration);