import { FC, memo } from "react";
import { ReactComponent as Earth } from "../../../assets/earth_fromspace.svg"

interface Props {
    className?:string;
}

const EarthFromSpace: FC<Props> = ({className}) => {
    return (
        <Earth className={className}/>
    );
};

export default memo(EarthFromSpace);