export const getEnv = async (envKey:string) => {
    const env = process.env;
    const response = await fetch (`${process.env.PUBLIC_URL}/env.json`);
    let json = null
    try {
        json = await response.json();
    } catch (error) {
        console.error(error);
    }
    
    if(!json){
        return env[envKey];
    }
    const fromJson = json[envKey];
    if(fromJson){
        return fromJson;
    }else{
        return env[envKey];
    }
}