export interface CMSPlayItem extends TimeItem {
    readonly ScreenID: number;
    readonly Layer: number;
    readonly MediaItemID: number;
    readonly Language?: string;
    readonly Volume: number;
    readonly Loop: boolean;
    readonly PlaybackSpeed: number;
}

export interface EndDemo extends TimeItem {}

export interface FieldControlsCommand extends TimeItem {
    readonly CommandType: CommandTypeEnum;
    readonly Target?: string;
    readonly Value?: string;
}

export interface Quiz extends TimeItem {
    readonly CMSScreenID: number;
    readonly QuestionID: string;
    readonly MaxResponseTime: string; // Timespan
    readonly ShowResultTime: string; // Timespan
    readonly ShowScoreTime: string; // Timespan
    readonly QuizMetadata?: QuizMetaData;
}

export interface UserAppCommand extends TimeItem {
    readonly CommandType: string;
    readonly Displaytext: string;
    readonly Value: string;
}

export interface UserAppFeedback extends TimeItem {
    readonly MediaItemID: string;
    readonly Progress: number;
    readonly MaxProgress: number;
}

export interface TimeItem {
    readonly ItemID: number;
    readonly DependsOn?: number;
    readonly StartOn?: StartPointEnum;
    readonly Delay: string; // TimeSpan
}

export enum StartPointEnum {
    Null,
    With,
    After,
}

export enum CommandTypeEnum {
    LightSwitch,
    Fan,
}

export interface QuizMetaData{
    readonly Type?: string;
    readonly ScoreAnimationDelay?: string; //Timespan
    readonly ResultAnimationDelay?: string; //Timespan
}