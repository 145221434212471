import {
    AuthenticateDTO,
    AuthenticationResultDTO,
    RegisterDTO,
} from "../../types/apis";
import { getBaseFetchParams, getBaseUrl, fetchWithOkCheck } from "../api.utils";

export const refreshToken = async (): Promise<AuthenticationResultDTO> =>
    await (
        await fetchWithOkCheck(`${await getBaseUrl("auth")}/refresh-token`, {
            ...getBaseFetchParams(),
            method: "POST",
        })
    ).json();

export const register = async (
    user: RegisterDTO
): Promise<AuthenticationResultDTO> =>
    await (
        await fetchWithOkCheck(`${await getBaseUrl("auth")}/register`, {
            ...getBaseFetchParams(),
            method: "POST",
            body: JSON.stringify(user),
        })
    ).json();

export const login = async (
    user: AuthenticateDTO
): Promise<AuthenticationResultDTO> =>
    await (
        await fetchWithOkCheck(`${await getBaseUrl("auth")}/login`, {
            ...getBaseFetchParams(),
            method: "POST",
            body: JSON.stringify(user),
        })
    ).json();

export const logout = async (): Promise<boolean> => {
    const response = await fetchWithOkCheck(`${await getBaseUrl("auth")}/logout`, {
        ...getBaseFetchParams(),
        method: "POST",
    });

    if (!response.ok) throw new Error(`${response.status}`);

    return true;
};
