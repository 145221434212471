import { FC, memo } from "react";
import LTLogoFooter from "../components/common/LTLogo/LTLogoFooter";
import "./css/LoadingPage.css";

interface Props {}

const LoadingPage: FC<Props> = () => {
    return (
        <div className="loadingPage">
            <i className="fa-solid fa-spinner spinAnimationFast"></i>
            <LTLogoFooter/>
        </div>
    );
};

export default memo(LoadingPage);
