import jwt_decode from "jwt-decode";
import { getEnv } from "../helpers/envHelper";
import { BaseErrorCodes } from "../types/apis";
import { refreshToken } from "./api-requests/authentication";

export const getBaseFetchParams = (token?: string, credentials?: RequestCredentials): RequestInit => ({
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: credentials || "include", // include, *same-origin, omit
    headers: {
        "Content-Type": "application/json",
        Authorization: token ? `Bearer ${token}` : "",
        // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
});

export const getBaseUrl = async (controllerName: string): Promise<string> =>
    `${await getEnv('REACT_APP_API_BASE')}/api/${controllerName}`;

export const getCMSBaseUrl = async (controllerName: string): Promise<string> =>
    `${await getEnv('REACT_APP_CMS_URI')}/api/${controllerName}`;

export const responseIsOk = (res: Response) => res.ok;

export const fetchRefreshIntegrated = async <T>(
    url: RequestInfo,
    init?: RequestInit
): Promise<T> => {
    let token = document?.cookie?.split('; ')?.find(row => row.startsWith('jwt-token='))?.split('=')[1];

    if (!token || token?.trim().length <= 0) {
        token = await getNewTokenUsingRefreshToken();
    } else {
        const tokenExpiryDate = getExpiryDateFromToken(token).getTime();

        if (tokenExpiryDate <= Date.now()) {
            token = await getNewTokenUsingRefreshToken();
        }
    }

    const response = await fetchWithOkCheck(url, {
        ...getBaseFetchParams(token),
        ...init,
    });

    const jsonRepsonse = await response.json();
    return jsonRepsonse;
};

export const fetchSimple = async <T>(
    url: RequestInfo,
    init?: RequestInit
): Promise<T> => {

    const response = await fetchWithOkCheck(url, {
        ...getBaseFetchParams(undefined, "omit"),
        ...init,
    });

    const jsonRepsonse = await response.json();
    return jsonRepsonse;
};

export const fetchRefreshIntegratedNoResponse = async (
    url: RequestInfo,
    init?: RequestInit
) => {
    let token = document?.cookie?.split('; ')?.find(row => row.startsWith('jwt-token='))?.split('=')[1];

    if (!token || token?.trim().length <= 0) {
        token = await getNewTokenUsingRefreshToken();
    } else {
        const tokenExpiryDate = getExpiryDateFromToken(token).getTime();

        if (tokenExpiryDate <= Date.now()) {
            token = await getNewTokenUsingRefreshToken();
        }
    }

    await fetchWithOkCheck(url, {
        ...getBaseFetchParams(token),
        ...init,
    });
};

export const getExpiryDateFromToken = (token: string): Date => {
    const decodedToken = jwt_decode<{ exp: number }>(token);
    return new Date(new Date(0).setUTCSeconds(decodedToken.exp));
};

export const setJwtTokenAsCookie = (token: string): void => {
    const expiryString = getExpiryDateFromToken(token).toUTCString();
    document.cookie = `jwt-token=${token}; path=/; expires=${expiryString}`;
};

export const getCorrectErrorMessage = <T>(err: Error): string => {
    let errorCode: BaseErrorCodes | T = "undefined-error";
    const isStatusCode = !isNaN(parseInt(err.message));

    if (isStatusCode) {
        switch (err.message) {
            case "400":
                errorCode = err.message as unknown as T;
                break;
            case "401":
                errorCode = "unauthorized" as BaseErrorCodes;
                break;
            case "403":
                errorCode = "forbidden" as BaseErrorCodes;
                break;
            case "404":
                errorCode = "not-found" as BaseErrorCodes;
                break;
        }
    } else if (err.message === "Bad Request") {
        errorCode = "bad-request" as BaseErrorCodes;
    } else if (err.message === "Failed to fetch") {
        errorCode = "failed-to-fetch" as BaseErrorCodes;
    } else {
        errorCode = err.message as unknown as T;
    }

    return errorCodeMap[errorCode as keyof ErrorCodeMap];
};

export const errorCodeMap = {
    "empty-token": "Token was not filled in.",
    "user-not-authenticated": "User was not authenticated.",
    "invalid-token": "The login token was incorrect.",
    "user-not-created": "A user could not be created. Try again later.",
    "undefined-error": "An unknown error has occured.",
    "failed-to-fetch":
        "A connection to the server couldn't be made, try again later.",
    "bad-request": "Uncaught bad request to the server happend.",
    unauthorized: "",
    forbidden: "",
    "not-found": "The requested resource could not be found.",
};

export type ErrorCodeMap = typeof errorCodeMap;

export const fetchWithOkCheck = async (
    input: RequestInfo,
    init?: RequestInit | undefined
): Promise<Response> => {
    const response = await fetch(input, init);

    if (!response.ok) {
        if (response.status === 400 || response.status === 500) {
            const errors = await response.json();
            throw errors;
        }
        throw new Error(`${response.status}`);
    }
    return response;
};

const getNewTokenUsingRefreshToken = async (): Promise<string> => {
    const { jwtToken } = await refreshToken();
    if (!jwtToken || jwtToken?.trim() === "")
        throw new Error("user-not-authenticated");

    setJwtTokenAsCookie(jwtToken);
    return jwtToken;
};
