import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StateStatus } from "../../types";
import { APIError, APIResponse, AuthenticateDTO, AuthenticationResultDTO, RegisterDTO, UserDTO } from "../../types/apis";
import { register, logout, login } from "../api-requests/authentication";
import { getUserByToken, patchUserAvatar, patchUserDemo } from "../api-requests/users";
import { getCorrectErrorMessage, setJwtTokenAsCookie } from "../api.utils";
import { AuthenticationError, AuthenticationState } from "./authentication.types";

/**
 *	Registers a new users to the DB
 *
 *	@returns APIResponse<UserDTO> OR APIError
 */
export const registerUser = createAsyncThunk<
	// Return type of the payload creator
	APIResponse<AuthenticationResultDTO>,
	// First argument to the payload creator
	RegisterDTO,
	// Types for ThunkAPI
	{
		rejectValue: APIError<string>;
	}
>("users/register", async (user, thunkAPI) => {
	try {
		const response = await register(user);

		if (!response) {
			const errorObj: APIError<AuthenticationError> = { Errors: "user-not-created" };
			throw errorObj;
		}

		return { response };
	} catch (err) {
		let rejectValue: APIError<string> = { Errors: getCorrectErrorMessage<AuthenticationError>(err as Error) };

		return thunkAPI.rejectWithValue(rejectValue);
	}
});

/**
 *	Gets the profile of a user using a jwt-token
 *
 *	@returns APIResponse<UserDTO> OR APIError
 */
export const getProfileUsingToken = createAsyncThunk<
	// Return type of the payload creator
	APIResponse<UserDTO>,
	// First argument to the payload creator
	undefined,
	// Types for ThunkAPI
	{
		stateValue: AuthenticationState;
		rejectValue: APIError<string>;
	}
>("users/me", async (_, thunkAPI) => {
	try {
		const response = await getUserByToken();
		return { response };
	} catch (err) {
		let rejectValue: APIError<string> = { Errors: getCorrectErrorMessage<AuthenticationError>(err as Error) };

		return thunkAPI.rejectWithValue(rejectValue);
	}
});

/**
 *	Logs a user out
 *
 *	@returns APIResponse<boolean> OR APIError
 */
export const logUserOut = createAsyncThunk<
	// Return type of the payload creator
	APIResponse<boolean>,
	// First argument to the payload creator
	undefined,
	// Types for ThunkAPI
	{
		rejectValue: APIError<string>;
	}
>("users/logout", async (_, thunkAPI) => {
	try {
		const response = await logout();

		return { response };
	} catch (err) {
		let rejectValue: APIError<string> = { Errors: getCorrectErrorMessage<AuthenticationError>(err as Error) };

		return thunkAPI.rejectWithValue(rejectValue);
	}
});

/**
 *	Logs a user in
 *
 *	@returns APIResponse<AuthenticateDTO> OR APIError
 */
export const logUserIn = createAsyncThunk<
	// Return type of the payload creator
	APIResponse<AuthenticationResultDTO>,
	// First argument to the payload creator
	AuthenticateDTO,
	// Types for ThunkAPI
	{
		rejectValue: APIError<string>;
	}
>("users/login", async (user, thunkAPI) => {
	try {
		const response = await login(user);

		return { response };
	} catch (err) {
		let rejectValue: APIError<string> = { Errors: getCorrectErrorMessage<AuthenticationError>(err as Error) };

		return thunkAPI.rejectWithValue(rejectValue);
	}
});

/**
 *	Modifies a user's avatar
 *
 *	@returns APIResponse<UserDTO> OR APIError
 */
export const setUserAvatar = createAsyncThunk<
	// Return type of the payload creator
	APIResponse<UserDTO>,
	// First argument to the payload creator
	string,
	// Types for ThunkAPI
	{
		rejectValue: APIError<string>;
	}
>("users/modify-avatar", async (newAvatarId, thunkAPI) => {
	try {
		const response = await patchUserAvatar(newAvatarId);

		return { response };
	} catch (err) {
		let rejectValue: APIError<string> = { Errors: getCorrectErrorMessage<AuthenticationError>(err as Error) };

		return thunkAPI.rejectWithValue(rejectValue);
	}
});

/**
 *	Modifies a user's demo
 *	This was added for users who join a group after a demo has been assigned. 
 *
 *	@returns APIResponse<UserDTO> OR APIError
 */
export const setUserDemo = createAsyncThunk<
	// Return type of the payload creator
	APIResponse<UserDTO>,
	// First argument to the payload creator
	{userId: string, demoId: string},
	// Types for ThunkAPI
	{
		rejectValue: APIError<string>;
	}
>("users/modify-demo", async (props, thunkAPI) => {
	try {
		const response = await patchUserDemo(props.userId, props.demoId);

		return { response };
	} catch (err) {
		let rejectValue: APIError<string> = { Errors: getCorrectErrorMessage<AuthenticationError>(err as Error) };

		return thunkAPI.rejectWithValue(rejectValue);
	}
});

const initialState: AuthenticationState = {
	status: StateStatus.idle,
	user: null,
	error: null,
	triedLoggingIn: false
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setTriedLoggingIn: (state, action: PayloadAction<boolean>) => {
			state.triedLoggingIn = action.payload;
		},
		setUser: (state, action:PayloadAction<UserDTO>) => {
			state.user = action.payload;
		}
	},
	extraReducers: (builder) => {
		// Register new user
		builder.addCase(registerUser.pending, (state) => {
			state.status = StateStatus.fetching;
			state.error = null;
		});
		builder.addCase(registerUser.rejected, (state, action) => {
			state.status = StateStatus.error;
		});
		builder.addCase(registerUser.fulfilled, (state, action) => {
			const { jwtToken, user } = action.payload.response;
			state.status = StateStatus.idle;
			setJwtTokenAsCookie(jwtToken);
			state.user = { ...user };
		});
		// Get own userprofile
		builder.addCase(getProfileUsingToken.pending, (state) => {
			state.status = StateStatus.fetching;
			state.error = null;
		});
		builder.addCase(getProfileUsingToken.rejected, (state, action) => {
			state.status = StateStatus.error;
			state.error = action.payload ?? null;
		});
		builder.addCase(getProfileUsingToken.fulfilled, (state, action) => {
			const user = { ...action.payload.response };
			state.status = StateStatus.idle;
			state.user = user;
		});
		// Log user out
		builder.addCase(logUserOut.pending, (state) => {
			state.status = StateStatus.fetching;
			state.error = null;
		});
		builder.addCase(logUserOut.rejected, (state, action) => {
			state.status = StateStatus.error;
			state.error = action.payload ?? null;
		});
		builder.addCase(logUserOut.fulfilled, (state, action) => {
			state.status = StateStatus.idle;
			if (action.payload.response) {
				state.user = null;
			}
		});
		// Log user in
		builder.addCase(logUserIn.pending, (state) => {
			state.status = StateStatus.fetching;
			state.error = null;
		});
		builder.addCase(logUserIn.rejected, (state, action) => {
			state.status = StateStatus.error;
			state.error = action.payload ?? null;
		});
		builder.addCase(logUserIn.fulfilled, (state, action) => {
			const { jwtToken, user } = action.payload.response;
			state.status = StateStatus.idle;
			setJwtTokenAsCookie(jwtToken);
			state.user = { ...user };
		});
		// Set user's avatar
		builder.addCase(setUserAvatar.pending, (state) => {
			state.status = StateStatus.fetching;
			state.error = null;
		});
		builder.addCase(setUserAvatar.rejected, (state, action) => {
			state.status = StateStatus.error;
			state.error = action.payload ?? null;
		});
		builder.addCase(setUserAvatar.fulfilled, (state, action) => {
			const user = action.payload.response;
			state.status = StateStatus.idle;
			state.user = { ...user };
		});
		builder.addCase(setUserDemo.pending, (state) => {
			state.status = StateStatus.fetching;
			state.error = null;
		});
		builder.addCase(setUserDemo.rejected, (state, action) =>{
			state.status = StateStatus.error;
			state.error = action.payload ?? null;
		})
		builder.addCase(setUserDemo.fulfilled, (state, action) =>{
			const user = action.payload.response;
			state.status = StateStatus.idle;
			state.user = { ...user }
		})
	}
});

export const { setTriedLoggingIn } = authSlice.actions;

export default authSlice.reducer;
