import { FC, memo } from "react";
import { getLocalizedText } from "../../../helpers/localizationHelper";
import { useAppSelector } from "../../../store";
import LTButton from "../UI-Elements/LTButton";
import "./css/LTCookieBanner.css";

interface Props {
    onClose?:()=>void;
}

const LTCookieBanner : FC<Props> = ({onClose = () => {}}) => {
    const { dictionary, defaultLocale } = useAppSelector((state) => state.localization);

    return (<div className="cookieBannerContainer fadeInAnimation">
        <div className="cookieBanner flyInAnimationBottom">
            <div className="left">
            <p>
             {getLocalizedText(dictionary,"cookiebanner",defaultLocale.id)}
            </p>
            </div>
            <div className="right">
            <LTButton onClick={() => {
                onClose();
            }}><div className="buttonContent"><i className="fa-solid fa-cookie"></i>&nbsp; {getLocalizedText(dictionary,"iunderstand",defaultLocale.id)}</div></LTButton>
            </div>
        </div>
    </div>);
}

export default memo(LTCookieBanner);