import { GetCurrentConfigProps } from "../../types";
import { ConfigDTO } from "../../types/apis/serverDTO.types";
import { fetchRefreshIntegrated, getBaseUrl } from "../api.utils";

export const getCurrentConfig = async ({
    demoId,
    gameVersion,
}: GetCurrentConfigProps): Promise<ConfigDTO | null> =>
    await fetchRefreshIntegrated<ConfigDTO>(
        `${await getBaseUrl("configs")}/${demoId}${gameVersion != null ? "/" + gameVersion : ""}`
    );

export const getAvailableGameVersions = async (
    demoId: string
): Promise<string[] | null> => {
    const baseConfigTemplatesURL = await getBaseUrl("configTemplates");
    return await fetchRefreshIntegrated<string[]>(
        `${baseConfigTemplatesURL}/gameVersions${demoId}`
    );
};
