import {
    HubConnection,
    HubConnectionBuilder,
    HubConnectionState,
    LogLevel,
} from "@microsoft/signalr";
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useRef,
} from "react";
import { useCookie } from "react-use";
import { useLogger } from ".";
import { useAppDispatch, useAppSelector } from "../store";
import { getProfileUsingToken } from "../store/authentication/authenticationSlice";
import { fetchCurrentConfig } from "../store/configs/configSlice";
import { fetchCurrentGroup } from "../store/groups/groupsSlice";
import { setDefaultLocale } from "../store/localization/localizationSlice";
import { TimelineStatus } from "../store/signalr/signalr.types";
import {
    receiveMessage,
    setConnectionStatus,
    updateMemberCount,
    setCurrentQuiz,
    setTimeLineStatus,
    setCurrentItemId,
    fetchLikedDemosForUser,
    setCurrentMediaItemId,
    setCachedObjectDto,
    setCurrentRandomAnswerDto,
} from "../store/signalr/signalrSlice";
import {
    CacheData,
    EndTour,
    GetCacheProps,
    JoinGroupProps,
    Logger,
    MemberCountUpdate,
    MessageToUserProps,
    RandomAnswerDTO,
    ReceiveMessage,
    RequestEmail,
    SendMessage,
    SendMessageToCaller,
    SendMessageToGroup,
    SendMessageToUsers,
    SignalRContextState,
    SignalRMethod,
    SignalRProviderProps,
    TimelineMessage,
    TimeLineStopped,
} from "../types";
import { getEnv } from "./envHelper";

export const handleSendMessage = async <T extends {}>(
    methodName: SignalRMethod,
    data: T,
    logger: Logger,
    sr?: HubConnection
): Promise<boolean> => {
    if (sr?.state === HubConnectionState.Connected) {
        return sr
            .send(methodName, ...Object.values(data))
            .then((res) => {
                return true;
            })
            .catch((reason) => {
                const loggingObject = {
                    objectToLog: { error: reason },
                    text: "Error while sending message",
                    title: "handleSendMessage",
                };
                logger ? logger.log(loggingObject) : console.log(loggingObject);

                return false;
            });
    }

    logger.warn({
        error: "Signal R wasn't connected, could not send the message.",
    });
    return true;
};

export const handleInvokeMethod = async <T extends {}>(
    methodName: SignalRMethod,
    data: T,
    logger: Logger,
    sr?: HubConnection
): Promise<any | null> => {
    if (sr?.state === HubConnectionState.Connected) {
        return sr
            .invoke(methodName, ...Object.values(data))
            .then((response) => {
                return response;
            })
            .catch((reason) => {
                const loggingObject = {
                    objectToLog: { error: reason },
                    text: "Error while invoking method",
                    title: "handleInvokeMethod",
                };
                logger ? logger.log(loggingObject) : console.log(loggingObject);

                return null;
            });
    }

    logger.warn({
        error: "Signal R wasn't connected, could not send the message.",
    });
    return null;
};

/**
 * Defines a structure for the SignalR context
 */
const SignalRContext = createContext<SignalRContextState>({
    startConnection: () => { },
    stopConnection: () => { },
    sendMessage: () => { },
    sendMessageToCaller: () => { },
    sendMessageToGroup: () => { },
    sendMessageToUsers: () => { },
    handleUpdateConfig: () => { },
    handleUpdateGroup: () => { },
    handleStartUserAppFeedback: (demoName: string,
        itemId: number,
        mediaItemId: string,) => { },
    handleStartShowQuestion: (demoName: string,
        callerGroupId: string,
        itemId: number,
        questionId: string) => { },
    handleStartResponses: (demoName: string,
        callerGroupId: string,
        itemId: number,
        questionId: string) => { },
    handleStartShowResult: (demoName: string,
        callerGroupId: string,
        itemId: number,
        questionId: string) => { },
    handleStartShowScore: (demoName: string,
        callerGroupId: string,
        itemId: number,
        questionId: string) => { },
    handleDemoChanged: (demoId: string) => { },
    handleStartShowResultRandom: (demoName: string,
        callerGroupId: string,
        itemId: number,
        questionId: string,
        randomAnswerDTO: RandomAnswerDTO) => { },
    handleStopShowResult: (demoId:string, callerGroupId:string, itemId:number, questionId:string) => {},
    handleStopShowScore: (demoId:string, callerGroupId:string, itemId:number, questionId:string) => {},
    handleRequestEmail: () => {}
});

export const SignalRProvider = ({ children }: SignalRProviderProps) => {
    const sr = useRef<HubConnection>();
    const { user } = useAppSelector((state) => state.auth);
    const userRef = useRef(user);
    const [,,deleteShowEmailForm] = useCookie('showEmailForm');
    useEffect(() => {
        //workaround to make sure user state is always up to date inside handler functions
        if(user !== undefined){
            userRef.current = user;
        }
    },[user])

    const logger = useLogger();
    const dispatch = useAppDispatch();
    const handleSendMessageGeneral: SendMessage = useCallback(
        (message: string) => {
            handleSendMessage<MessageToUserProps>(
                "SendMessage",
                {
                    message,
                    userId: userRef?.current?.id || "undefined",
                },
                logger,
                sr.current
            );
        },
        [logger]
    );

    const handleSendMessageToCaller: SendMessageToCaller = useCallback(
        (message: string) => {
            handleSendMessage<MessageToUserProps>(
                "SendMessageToCaller",
                {
                    message,
                    userId: userRef?.current?.id || "undefined",
                },
                logger,
                sr.current
            );
        },
        [logger]
    );

    const handleSendMessageToGroup: SendMessageToGroup = useCallback(
        (message: string) => {
            handleSendMessage<MessageToUserProps>(
                "SendMessageToGroup",
                {
                    message,
                    userId: userRef?.current?.id || "undefined",
                },
                logger,
                sr.current
            );
        },
        [logger]
    );

    const handleSendMessageToUsers: SendMessageToUsers = useCallback(
        (message: string) => {
            handleSendMessage<MessageToUserProps>(
                "SendMessageToUser",
                {
                    message,
                    userId: userRef?.current?.id || "undefined",
                },
                logger,
                sr.current
            );
        },
        [logger]
    );

    const handleStartShowQuestion: TimelineMessage = useCallback(
        (demoId:string, callerGroupId:string, itemId:number, questionId:string) => {
            if(demoId === userRef?.current?.currentDemo?.id && userRef?.current?.groupId === callerGroupId){
                dispatch(setCurrentItemId(itemId));
                dispatch(setCurrentQuiz(questionId));
                dispatch(setTimeLineStatus(TimelineStatus.ShowQuizQuestion));
            }
        },
        [dispatch]
    );

    const handleStartResponses: TimelineMessage = useCallback(
        (demoId:string, callerGroupId:string, itemId:number, questionId:string) => {
            if(demoId === userRef?.current?.currentDemo?.id && userRef?.current?.groupId === callerGroupId){
                dispatch(setCurrentItemId(itemId));
                dispatch(setCurrentQuiz(questionId));
                dispatch(setTimeLineStatus(TimelineStatus.ShowPossibleResponses));
            }
        },
        [dispatch]
    );
    const handleStartShowResult: TimelineMessage = useCallback(
        (demoId:string, callerGroupId:string, itemId:number, questionId:string) => {
            if(demoId === userRef?.current?.currentDemo?.id && userRef?.current?.groupId === callerGroupId){
            dispatch(setCurrentItemId(itemId));
            dispatch(setCurrentQuiz(questionId));
            dispatch(setTimeLineStatus(TimelineStatus.ShowResult));
            }
        },
        [dispatch]
    );

    const handleStartShowResultRandom: TimelineMessage = useCallback(
        (demoId:string, callerGroupId:string, itemId:number, questionId:string, randomAnswerDTO:RandomAnswerDTO) => {
            if(demoId === userRef?.current?.currentDemo?.id && userRef?.current?.groupId === callerGroupId){
            dispatch(setCurrentItemId(itemId));
            dispatch(setCurrentQuiz(questionId));
            dispatch(setCurrentRandomAnswerDto(randomAnswerDTO));
            dispatch(setTimeLineStatus(TimelineStatus.ShowResultRandom));
            }
        },
        [dispatch]
    );

    const handleStopShowResult: TimelineMessage = useCallback(
        (demoId:string, callerGroupId:string, itemId:number, questionId:string) => {
            if(demoId === userRef?.current?.currentDemo?.id && userRef?.current?.groupId === callerGroupId){
                dispatch(setCurrentItemId(itemId));
                dispatch(setCurrentQuiz(questionId));
                dispatch(setTimeLineStatus(TimelineStatus.Idle));
            }
        },
        [dispatch]
    );

    const handleGetCache = useCallback(async () => {
        if (userRef?.current?.groupId) {
            await handleInvokeMethod<GetCacheProps>(
                "GetCache",
                { callerGroupID: userRef?.current?.groupId },
                logger,
                sr.current
            );
        }
    }, [logger]);

    const handleConnected = useCallback(
        async (connectionId?: string) => {
            if (userRef?.current?.groupId != null) {
                const response = await handleSendMessage<JoinGroupProps>(
                    "JoinGroup",
                    { groupId: userRef?.current?.groupId, userId: userRef?.current?.id },
                    logger,
                    sr.current
                );

                if (response) {
                    await dispatch(
                        setConnectionStatus(HubConnectionState.Connected)
                    );
                    await dispatch(getProfileUsingToken());
                    await handleGetCache();
                }
            } else {
                logger.log({
                    title: "[RoomPage.tsx] handleConnected",
                    text: "Couldn't join a group because groupId wasn't present.",
                });
            }
        },
        [dispatch, handleGetCache, logger]
    );

    const handleErrorOnConnect = useCallback(
        (reason: string) => {
            dispatch(setConnectionStatus(HubConnectionState.Disconnected));
            logger.log({
                title: "[RoomPage.tsx] - handleErrorOnConnect",
                objectToLog: { errorOnConnect: reason },
            });
        },
        [dispatch, logger]
    );

    const handleReconnectingError = useCallback(
        (error?: Error) => {
            dispatch(setConnectionStatus(HubConnectionState.Connecting));
            logger.log({
                title: "[RoomPage.tsx] - handleReconnectingError",
                objectToLog: {
                    reconnectingReason: error?.message || "No reason.",
                },
            });
        },
        [dispatch, logger]
    );

    const handleUpdateGroup = useCallback(
        async (roomId: string) => {
            await dispatch(fetchCurrentGroup(userRef?.current?.groupId || ""));
            if (userRef?.current?.id !== undefined) {
                await dispatch(fetchLikedDemosForUser(userRef?.current?.id));
                await dispatch(getProfileUsingToken());
            }

            logger.log({
                title: "[RoomPage.tsx] - handleUpdateGroup",
                text: `Switched room to room with id: ${roomId}`,
            });
        },
        [dispatch, logger]
    );

    const handleUpdateConfig = useCallback(
        async (demoId: string) => {
            if (demoId) {
                await dispatch(fetchCurrentConfig({ demoId }));

                logger.log({
                    title: "[RoomPage.tsx] - handleUpdateConfig",
                    text: `Switched to config for demo with id: ${demoId}`,
                });
            } else {
                console.error("demoId is undefined");
            }
        },
        [dispatch, logger]
    );

    const handleReconnected = useCallback(
        async (connectionId?: string) => {
            await handleConnected();

            await handleUpdateGroup("reconnecting to SignalR");
        },
        [handleConnected, handleUpdateGroup]
    );

    const handleConnectionClose = useCallback(() => {
        dispatch(setConnectionStatus(HubConnectionState.Disconnected));
    }, [dispatch]);

    const handleDemoChanged = useCallback(
        async (demoId: string, groupId: string) => {
            // Update the user profile to get the changed demoId in the state
            await dispatch(getProfileUsingToken());
            await dispatch(setTimeLineStatus(TimelineStatus.Idle));
            logger.log({
                title: "[RoomPage.tsx] - handleDemoChanged",
                text: `Updated user profile with new demo id: ${demoId}`,
            });
        },
        [dispatch, logger]
    );

    const handleReceiveMessage: ReceiveMessage = useCallback(
        (user, message) => {
            dispatch(receiveMessage({ user, message }));
        },
        [dispatch]
    );

    const handleMemberCountUpdate: MemberCountUpdate = useCallback(
        (newCount) => {
            dispatch(updateMemberCount(newCount));
        },
        [dispatch]
    );

    const handleStartShowScore: TimelineMessage = useCallback(
        (demoId:string, callerGroupId:string, itemId:number, questionId:string) => {
            if(demoId === userRef?.current?.currentDemo?.id && userRef?.current?.groupId === callerGroupId){
                dispatch(setCurrentItemId(itemId));
                dispatch(setCurrentQuiz(questionId));
                dispatch(setTimeLineStatus(TimelineStatus.ShowScore));
            }
        },
        [dispatch]
    );

    const handleStopShowScore: TimelineMessage = useCallback(
        (demoId:string, callerGroupId:string, itemId:number, questionId:string) => {
            if(demoId === userRef?.current?.currentDemo?.id && userRef?.current?.groupId === callerGroupId){
                dispatch(setCurrentItemId(itemId));
                dispatch(setCurrentQuiz(questionId));
                dispatch(setTimeLineStatus(TimelineStatus.Idle));
            }
        },
        [dispatch]
    );

    const handleStartUserAppFeedback: TimelineMessage = useCallback(
        (demoId:string, itemId:number, mediaItemId:string) => {
            if(demoId === userRef?.current?.currentDemo?.id){
            logger.log({
                title: "[SignalRHelper.tsx] - handleStartUserAppFeedback",
                text: `Started showing a UserAppFeedback item`,
            });
            dispatch(setTimeLineStatus(TimelineStatus.ShowUserAppFeedback));
            dispatch(setCurrentItemId(itemId));
            dispatch(setCurrentMediaItemId(mediaItemId));
        }
        },
        [dispatch, logger]
    );

    const handleEndTour: EndTour = useCallback(() => {
        logger.log({
            title: "[SingnalRHelper.tsx] - handleEndTour",
            text: `The admin has ended the tour`,
        });
        deleteShowEmailForm();
        dispatch(setDefaultLocale(userRef?.current?.locale));
        dispatch(setTimeLineStatus(TimelineStatus.EndTour));
    }, [deleteShowEmailForm, dispatch, logger]);

    const handleTimeLineStopped: TimeLineStopped = useCallback(async () => {
        logger.log({
            title: "[SignalRHelper.tsx] - handleTimeLineStopped",
            text: `The admin has ended the tour`,
        });
        await dispatch(setCachedObjectDto(null));
        await dispatch(setTimeLineStatus(TimelineStatus.EndDemo));
    }, [dispatch, logger]);

    const handleUnusigneDemo: TimeLineStopped = useCallback(async () => {
        logger.log({
            title: "[SignalRHelper.tsx] - handleUnusigneDemo",
            text: `The demo has been ended`,
        });

        await dispatch(getProfileUsingToken());
    }, [dispatch, logger]);

    const lockResolver = useRef<(value?: any) => void>();

    const setWebLock = useCallback(() => {
        if ("locks" in navigator) {
            const lockPromise = new Promise((res: (value: any) => void) => {
                lockResolver.current = res;
            });
            // Request the lock:
            navigator.locks.request(
                "sr_connection_lock",
                { mode: "shared" },
                () => {
                    return lockPromise;
                }
            );
        }
    }, []);

    const handleCacheData: CacheData = useCallback(
        async (cache) => {
            logger.log({
                title: "[SignalRHelper.tsx] - handleCacheData",
                text: `Received CacheData`,
            });
            await dispatch(setCachedObjectDto(cache));
        },
        [dispatch, logger]
    );

    const handleRequestEmail: RequestEmail = useCallback(
        () => {
            logger.log({
                title: "[SignalRHelper.tsx] - handleRequestEmail",
                text: `Start email request form.`,
            });
            dispatch(setTimeLineStatus(TimelineStatus.RequestEmail));
        }
    ,[dispatch, logger]);

    const handleNotifyMergedUsers = useCallback((fromUserId: string, toUserId: string) => {
        if(userRef.current?.id === fromUserId || userRef.current?.id === toUserId){
            logger.log({
                title: "[SignalRHelper.tsx] - handleNotifyMergedUsers",
                text: `This user has been merged.`,
            });
            window.location.reload();
        }
    },[logger]);

    const handleNotifyDeletedUser = useCallback((userId: string) => {
        if (userRef.current?.id === userId) {
            logger.log({
                title: "[SignalRHelper.tsx] - handleNotifyDeletedUser",
                text: `This user has been deleted.`,
            });
            window.location.reload();
        }
    },[logger]);

    const setHandlers = useCallback(() => {
        sr.current?.onreconnecting(handleReconnectingError);
        sr.current?.onreconnected(handleReconnected);
        sr.current?.onclose(handleConnectionClose);
        sr.current?.on("ReceiveMessage", handleReceiveMessage);
        sr.current?.on("MemberCountUpdate", handleMemberCountUpdate);
        sr.current?.on("StartShowQuestion", handleStartShowQuestion);
        sr.current?.on("StartResponses", handleStartResponses);
        sr.current?.on("StartShowResult", handleStartShowResult);
        sr.current?.on("StartShowResultRandom", handleStartShowResultRandom);
        sr.current?.on("StopShowResult", handleStopShowResult);
        sr.current?.on("StartShowScore", handleStartShowScore);
        sr.current?.on("StopShowScore", handleStopShowScore);
        sr.current?.on("RoomsChanged", async (roomId: string) => {
            await handleUpdateGroup(roomId);
        });
        sr.current?.on(
            "DemoChanged",
            async (demoId: string, groupId: string) => await handleDemoChanged(demoId, groupId)
        );
        sr.current?.on("TimeLineStopped", handleTimeLineStopped);
        sr.current?.on("EndTour", handleEndTour);
        handleConnected();

        sr.current?.on(
            "StartUserAppFeedback",
            handleStartUserAppFeedback
        );

        sr.current?.on("unusigneDemo", handleUnusigneDemo);

        sr.current?.on("CacheData", handleCacheData);
        sr.current?.on("requestEmail", handleRequestEmail);
       
        sr.current?.on("NotifyMergedUsers",handleNotifyMergedUsers);
        
        sr.current?.on("NotifyDeletedUser",handleNotifyDeletedUser);
    },[handleCacheData, handleConnected, handleConnectionClose, handleDemoChanged, handleEndTour, handleMemberCountUpdate, handleNotifyDeletedUser, handleNotifyMergedUsers, handleReceiveMessage, handleReconnected, handleReconnectingError, handleRequestEmail, handleStartResponses, handleStartShowQuestion, handleStartShowResult, handleStartShowResultRandom, handleStartShowScore, handleStartUserAppFeedback, handleStopShowResult, handleStopShowScore, handleTimeLineStopped, handleUnusigneDemo, handleUpdateGroup]);

    const startConnection = useCallback(async () => {
        setWebLock();
        let token = document?.cookie
            ?.split("; ")
            ?.find((row) => row.startsWith("jwt-token="))
            ?.split("=")[1];

        let baseUrl = await getEnv('REACT_APP_SR_URI');
        sr.current = new HubConnectionBuilder()
            .withUrl(`${baseUrl}/RHub`, {
                accessTokenFactory: () => token ?? ""
            })

            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect([0, 2000, 5000])
            .build();

        dispatch(setConnectionStatus(HubConnectionState.Connecting));

        sr.current.start().then(() => {
            setHandlers();
        }).catch(handleErrorOnConnect);
    }, [dispatch, handleErrorOnConnect, setHandlers, setWebLock]);

    const stopConnection = useCallback(() => {
        sr.current
            ?.stop()
            .catch((err) =>
                console.warn("Connection could not be terminated: %o", err)
            );
    }, []);

    useEffect(() => {
        if (user?.currentDemo?.id != null)
            handleUpdateConfig(user?.currentDemo?.id);
    }, [handleUpdateConfig, user?.currentDemo?.id]);

    return (
        <SignalRContext.Provider
            value={{
                sendMessage: handleSendMessageGeneral,
                sendMessageToCaller: handleSendMessageToCaller,
                sendMessageToGroup: handleSendMessageToGroup,
                sendMessageToUsers: handleSendMessageToUsers,
                startConnection,
                stopConnection,
                handleUpdateGroup,
                handleUpdateConfig,
                handleStartUserAppFeedback: handleStartUserAppFeedback,
                handleStartShowQuestion: handleStartShowQuestion,
                handleStartResponses: handleStartResponses,
                handleStartShowResult: handleStartShowResult,
                handleStartShowScore: handleStartShowScore,
                handleDemoChanged: handleDemoChanged,
                handleStartShowResultRandom: handleStartShowResultRandom,
                handleStopShowResult: handleStopShowResult,
                handleStopShowScore: handleStopShowScore,
                handleRequestEmail : handleRequestEmail
            }}
        >
            {children}
        </SignalRContext.Provider>
    );
};

export const useSignalR = () => useContext(SignalRContext);
