import { HubConnectionState } from "@microsoft/signalr";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import {
    APIError,
    QuizChosenAnswerPostDTO,
    QuizQuestion,
    QuizResponseCountDTO,
    QuizScoreOverviewDTO,
    QuizUserScoreDTO,
    RandomAnswer,
    RandomAnswerDTO,
    ReceiveMessageObject,
    StateStatus,
} from "../../types";
import { LikedDemoDTO } from "../../types/apis/models/likedDemos.types";
import { LocalizationData } from "../../types/apis/models/locale.types";
import { startConnectionWithHub } from "../api-requests/cache";
import { getLikedDemosForUser } from "../api-requests/likedDemos";
import { getCurrentScores, getResponseOverview, getScoreOverviewTake } from "../api-requests/quiz";
import { CachedObjectDto, SignalRState, TimelineStatus } from "./signalr.types";

const initialState: SignalRState = {
    status: StateStatus.idle,
    error: null,
    connectionStatus: HubConnectionState.Disconnected,
    timelineStatus: TimelineStatus.Idle,
    memberCount: 0,
    latestMessages: [],
    currentQuiz: null,
    currentScoreOverview: null,
    currentScores: null,
    currentItemId: null,
    currentResponseOverview: null,
    likedDemos: null,
    currentMediaItemId: null,
    connectedToCashGroup: false,
    cachedObjectDto: null,
    currentRandomAnswer: null
};

/**
 * Sets the correct quiz with the id
 * Throws an error when the response is empty or an error has been encountered during fetch/post
 *
 * @returns ConfigDTO OR APIError
 */
export const setCurrentQuiz = createAsyncThunk<
    // Return type of the payload creator
    QuizQuestion | null,
    // First argument to the payload creator
    string | null,
    // Types for ThunkAPI
    {
        state: RootState;
        rejectValue: APIError<string>;
    }
>("signalr/setCurrentQuiz", async (quizId, thunkAPI) => {
    if (quizId == null) return null;

    const { currentQuiz } = thunkAPI.getState().signalr;
    if (currentQuiz?.id === quizId) return currentQuiz;

    try {
        const { currentQuizzes, currentConfig } = thunkAPI.getState().configs;
        if (currentQuizzes != null && currentQuizzes.length > 0) {
            const newCurrentQuizDTO = currentQuizzes.find(
                (quiz) => quiz.id === quizId
            );
            const quizInfo = currentConfig?.Quizzes.find(
                (quiz) => quiz.QuestionID === quizId
            );

            if (newCurrentQuizDTO == null || quizInfo == null)
                throw new Error(`No quiz found with id ${quizId}`);

            const newCurrentQuiz: QuizQuestion = {
                id: newCurrentQuizDTO?.id,
                data: JSON.parse(newCurrentQuizDTO.data),
                quizAnswers: newCurrentQuizDTO.quizAnswers.map(
                    (answerDTO, index) => ({
                        ...answerDTO,
                        data: JSON.parse(
                            newCurrentQuizDTO.quizAnswers[index].data
                        ),
                    })
                ),
                quizInfo,
                backgroundImageUri: newCurrentQuizDTO.backgroundImageUri
            };

            return newCurrentQuiz;
        }

        throw new Error(
            `CurrentQuizzes was either null or length was 0: ${currentQuizzes}`
        );
    } catch (err) {
        return thunkAPI.rejectWithValue({
            Errors: (err as Error).message,
        } as APIError<string>);
    }
});

/**
 * Gets a new current score overview
 * Throws an error when the response is empty or an error has been encountered during fetch/post
 *
 * @returns QuizScoreOverviewDTO OR APIError
 */
export const getCurrentScoreOverview = createAsyncThunk<
    // Return type of the payload creator
    QuizScoreOverviewDTO,
    // First argument to the payload creator
    { groupId: string, questionId: string, take: number },
    // Types for ThunkAPI
    {
        state: RootState;
        rejectValue: APIError<string>;
    }
>("signalr/getCurrentScoreOverview", async ({ groupId, questionId, take }, thunkAPI) => {
    if (groupId == null || questionId == null || take == null) throw new Error(
        `groupId (${groupId}), questionId (${questionId}) or take(${take}) was null`
    );

    try {
        const overview = await getScoreOverviewTake(groupId, questionId, take);
        if (overview == null) throw new Error(
            "Response for getting score overview was null"
        );

        return overview;
    } catch (err) {
        return thunkAPI.rejectWithValue({
            Errors: (err as Error).message,
        } as APIError<string>);
    }
});

export const postQuizChosenAnswer = createAsyncThunk<
    // Return type of the payload creator
    void,
    // First argument to the payload creator
    { chosenAnswer: QuizChosenAnswerPostDTO },
    // Types for ThunkAPI
    {
        state: RootState;
        rejectValue: APIError<string>;
    }
>("signalr/postQuizChosenAnswer", async (chosenAnswer, thunkAPI) => {

    try {
        await postQuizChosenAnswer(chosenAnswer);
    } catch (err) {
        return thunkAPI.rejectWithValue({
            Errors: (err as Error).message,
        } as APIError<string>);
    }
});

/**
 * Gets the current scores
 * Throws an error when the response is empty or an error has been encountered during fetch/post
 *
 * @returns QuizUserScoreDTO[] OR APIError
 */
export const getCurrentUserScores = createAsyncThunk<
    // Return type of the payload creator
    QuizUserScoreDTO[],
    // First argument to the payload creator
    string,
    // Types for ThunkAPI
    {
        state: RootState;
        rejectValue: APIError<string>;
    }
>("signalr/getCurrentUserScores", async (groupId, thunkAPI) => {
    if (groupId == null) throw new Error(
        "groupId was null"
    );

    try {
        const scores = await getCurrentScores(groupId);

        if (scores == null) throw new Error(
            "Response for getting user scores was null"
        );

        return scores;
    } catch (err) {
        return thunkAPI.rejectWithValue({
            Errors: (err as Error).message,
        } as APIError<string>);
    }
});

/**
 * Gets the quiz response overview with counts
 * Throws an error when the response is empty or an error has been encountered during fetch/post
 *
 * @returns QuizResponseCountDTO[] OR APIError
 */
export const getQuizResponseOverview = createAsyncThunk<
    // Return type of the payload creator
    QuizResponseCountDTO[],
    // First argument to the payload creator
    { groupId: string, questionId: string },
    // Types for ThunkAPI
    {
        state: RootState;
        rejectValue: APIError<string>;
    }
>("signalr/getQuizResponseOverview", async ({ groupId, questionId }, thunkAPI) => {
    if (groupId == null) throw new Error(
        "groupId was null"
    );
    if (questionId == null) throw new Error(
        "questionId was null"
    );

    try {
        const responses:any = await getResponseOverview(groupId, questionId);

        if (responses == null) throw new Error(
            "Response for getting response count was null"
        );

        return responses;
    } catch (err) {
        return thunkAPI.rejectWithValue({
            Errors: (err as Error).message,
        } as APIError<string>);
    }
});

/**
 * Gets all the LikedDemos associated with the user
 * Throws an error when the response is empty or an error has been encountered during fetch/post
 *
 * @returns LikedDemoDTO[] OR APIError
 */
export const fetchLikedDemosForUser = createAsyncThunk<
    // Return type of the payload creator
    LikedDemoDTO[],
    // First argument to the payload creator
    string,
    // Types for ThunkAPI
    {
        rejectValue: APIError<string>;
    }
>("signalr/fetchLikedDemosForUser", async (userId, thunkAPI) => {
    if (userId == null) throw new Error(
        "userId was null"
    );

    try {
        const likedDemos:any = await getLikedDemosForUser(userId);

        if (likedDemos == null) throw new Error(
            "Response for getting liked demos was null"
        );

        return likedDemos;
    } catch (err) {
        return thunkAPI.rejectWithValue({
            Errors: (err as Error).message,
        } as APIError<string>);
    }
});



/**
 * Connect to Cache group
 *
 * @returns boolean
 */
export const StartConnectionWithCache = createAsyncThunk<
    // Return type of the payload creator
    boolean,
    // First argument to the payload creator
    undefined,
    // Types for ThunkAPI
    {
        rejectValue: APIError<string>;
    }
>("signalr/startConnectionWithCache", async (_, thunkAPI) => {

    try {
        const isConnected = await startConnectionWithHub();

        if (!isConnected) throw new Error(
            "Error while trying to connect Cache group"
        );

        return isConnected;
    } catch (err) {
        return thunkAPI.rejectWithValue({
            Errors: (err as Error).message,
        } as APIError<string>);
    }
});

 
export const signalrSlice = createSlice({
    name: "signalr",
    initialState,
    reducers: {
        receiveMessage: (
            state,
            action: PayloadAction<ReceiveMessageObject>
        ) => {
            if (state.latestMessages.length === 10) {
                let tempArray = [...state.latestMessages];
                tempArray.shift();
                state.latestMessages = [...tempArray, action.payload];
            } else {
                state.latestMessages = [
                    ...state.latestMessages,
                    action.payload,
                ];
            }
        },
        updateMemberCount: (state, action: PayloadAction<number>) => {
            state.memberCount = action.payload;
        },
        setConnectionStatus: (
            state,
            action: PayloadAction<HubConnectionState>
        ) => {
            state.connectionStatus = action.payload;
        },
        setTimeLineStatus: (state, action: PayloadAction<TimelineStatus>) => {
            state.timelineStatus = action.payload;
        },
        resetSavedScores: (state) => {
            state.currentScoreOverview = null;
            state.currentScores = null;
        },
        setCurrentItemId: (state, action: PayloadAction<number>) => {
            state.currentItemId = action.payload;
        },
        setCurrentMediaItemId: (state, action: PayloadAction<string>) => {
            state.currentMediaItemId = action.payload;
        },
        resetResponseOverview: (state) => {
            state.currentResponseOverview = null;
        },
        setCachedObjectDto: (state, action: PayloadAction<CachedObjectDto | null>) => {
            state.cachedObjectDto = action.payload;
        },
        setCurrentRandomAnswerDto: (state, action: PayloadAction<RandomAnswerDTO | null>) => {
            let quizQuestionData: LocalizationData[] = [];
            if(action.payload?.quizAnswer){
                quizQuestionData = JSON.parse(action.payload?.quizAnswer.data) as LocalizationData[];
            }
            let randomAnswer: RandomAnswer = {user: action.payload?.user, quizAnswer: {id:action.payload?.quizAnswer.id || "", correct: action.payload?.quizAnswer.correct || false, data: quizQuestionData, quizQuestionId: action.payload?.quizAnswer.quizQuestionId || ""}};
            state.currentRandomAnswer = randomAnswer;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(setCurrentQuiz.pending, (state) => {
            state.status = StateStatus.fetching;
        });
        builder.addCase(setCurrentQuiz.fulfilled, (state, { payload }) => {
            state.status = StateStatus.idle;
            state.currentQuiz = payload;
        });
        builder.addCase(setCurrentQuiz.rejected, (state, { payload }) => {
            state.status = StateStatus.error;
            state.error = payload?.Errors || null;
        });
        builder.addCase(getCurrentUserScores.pending, (state) => {
            state.status = StateStatus.fetching;
        });
        builder.addCase(getCurrentUserScores.fulfilled, (state, { payload }) => {
            state.status = StateStatus.idle;
            state.currentScores = payload;
        });
        builder.addCase(getCurrentUserScores.rejected, (state, { payload }) => {
            state.status = StateStatus.error;
            state.error = payload?.Errors || null;
        });
        builder.addCase(getCurrentScoreOverview.pending, (state) => {
            state.status = StateStatus.fetching;
        });
        builder.addCase(getCurrentScoreOverview.fulfilled, (state, { payload }) => {
            state.status = StateStatus.idle;
            state.currentScoreOverview = payload;
        });
        builder.addCase(getCurrentScoreOverview.rejected, (state, { payload }) => {
            state.status = StateStatus.error;
            state.error = payload?.Errors || null;
        });
        builder.addCase(getQuizResponseOverview.pending, (state) => {
            state.status = StateStatus.fetching;
        });
        builder.addCase(getQuizResponseOverview.fulfilled, (state, { payload }) => {
            state.status = StateStatus.idle;
            state.currentResponseOverview = payload;
        });
        builder.addCase(getQuizResponseOverview.rejected, (state, { payload }) => {
            state.status = StateStatus.error;
            state.error = payload?.Errors || null;
        });
        builder.addCase(fetchLikedDemosForUser.pending, (state) => {
            state.status = StateStatus.fetching;
        });
        builder.addCase(fetchLikedDemosForUser.fulfilled, (state, { payload }) => {
            state.status = StateStatus.idle;
            state.likedDemos = payload;
        });
        builder.addCase(fetchLikedDemosForUser.rejected, (state, { payload }) => {
            state.status = StateStatus.error;
            state.error = payload?.Errors || null;
        });
        builder.addCase(StartConnectionWithCache.pending, (state) => {
            state.status = StateStatus.fetching;
        });
        builder.addCase(StartConnectionWithCache.fulfilled, (state, { payload }) => {
            state.status = StateStatus.idle;
            state.connectedToCashGroup = payload;
        });
        builder.addCase(StartConnectionWithCache.rejected, (state, { payload }) => {
            state.status = StateStatus.error;
            state.error = payload?.Errors || null;
        });
 
    },
});

export const {
    receiveMessage,
    updateMemberCount,
    setConnectionStatus,
    setTimeLineStatus,
    resetSavedScores,
    resetResponseOverview,
    setCurrentItemId,
    setCurrentMediaItemId,
    setCachedObjectDto,
    setCurrentRandomAnswerDto
} = signalrSlice.actions;

export default signalrSlice.reducer;
