import { createTheme, responsiveFontSizes } from "@mui/material";

const theme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: "#55bcec",
            light: "rgba(70,150,190,1)"
        },
        secondary: {
            main: "#243f72",
            dark: "#1a4064"
        },
        info: {
            main: "rgba(26, 64, 100, 1)",
        },
        warning: {
            main: "rgba(150,40,60,1)",
        },
        error: {
            main: "#AE1E5A",
        },
        text: {
            primary: "#fff",
            secondary: "#243f72",
        },
        grey: {
            A100: "#eaeaea",
        },
        background: {
            default: "#243f72",
            paper: "#eaeaea",
        },
        success: {
            main: "rgb(0, 255, 8)"
        },
    },
    typography: {
        allVariants: {
            color: "#fff",
        },
        fontFamily: ["Open Sans", "sans-serif"].join(", "),
    },
    components: {
        MuiTab: {
            styleOverrides: {
                textColorPrimary: {
                    color: "#fff",
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                title: {
                    fontWeight: "bold",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                text: {
                    fontWeight: "bold",
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: "#fff",
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    marginRight: "0.5rem",
                    fontWeight: "bold",
                },
                labelPlacementStart: {
                    marginLeft: 0,
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    marginLeft: "-12px",
                },
            },
        },
    },
});

export default responsiveFontSizes(theme);
