import { MediaUriDTO } from "../types/apis/models/mediaItem.types";
import { KeyValue } from "../types";
import { LocalizationData } from "../types/apis/models/locale.types";

export const getMediaItemUriByLocaleOrFirst = (mediaItemUri?: MediaUriDTO[], locale?: string) => {
    if(!locale){
        locale = "en";
    }
    return mediaItemUri ? mediaItemUri?.find(x => x.locale === locale)?.uri || mediaItemUri[0]?.uri : null;
}
export const getLocalizedValueByLocaleOrFirst = (
    localizationData: LocalizationData[] | undefined,
    locale?: string
): LocalizationData | null => {
    if(!locale){
        locale = "en";
    }
    return localizationData
        ? localizationData?.find((data) => data.language === locale) || localizationData[0]
        : null;
};
export const getLocalizedText = (dictionary: KeyValue<KeyValue<string>> | null, key: string, locale?: string) => {
    if(dictionary === null){
        return "";
    }
    let translation;
    if(locale){
        translation = dictionary[locale]?.[key] || null;
    }
    
    if(translation){
        return translation;
    }

    translation = dictionary['en']?.[key];
    if(translation){
        return translation;
    }
    console.error(`Invalid key: ${key}. Cannot get localization`)
    return '';
};