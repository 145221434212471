import { FC, memo } from "react";
import { ReactComponent as Logo } from "../../../assets/lt_app_logo.svg";

interface Props {
    className?:string;
}

const LTLogo: FC<Props> = ({className}) => {
    return (
        <Logo className={className}
            filter="brightness(0) saturate(100%) invert(97%) sepia(35%) saturate(184%) hue-rotate(185deg) brightness(110%) contrast(84%)"
        />
    );
};

export default memo(LTLogo);
