import { FC, memo } from "react";
import "./css/LTButton.css";

interface Props{
    className?: string;
    onClick?:() => void;
    mode?: string | "idle" | "selected" | "disabled" | "selected disabled" | "idle disabled" | "progress";
    children?: JSX.Element | JSX.Element[] | string | any;
    progress?:string;
    type?:"button" | "submit" | "reset";
    disabled?:boolean;
}

const LTButton: FC<Props> = ({className = "", onClick, mode = "idle", children, progress = "0%", type = "button", disabled=false}) => {
    return (
    <button className={`ltButton ${mode} ${className}`} onClick={onClick} type={type} disabled={disabled}>
        <div className="progressBar" style={{width:`${progress}`, height:"100%"}} >    
        </div>
        <div className="children">
                {children}
        </div>
    </button>
    )
}

export default memo(LTButton);