import { CssBaseline, ThemeProvider } from "@mui/material";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { LoggerProvider, SignalRProvider } from "./helpers";
import "./index.css";
import ErrorPage from "./pages/ErrorPage";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store";
import theme from "./theme";
import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/500.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/open-sans/800.css";
import "@fontsource/open-sans/300-italic.css";
import "@fontsource/open-sans/400-italic.css";
import "@fontsource/open-sans/500-italic.css";
import "@fontsource/open-sans/600-italic.css";
import "@fontsource/open-sans/700-italic.css";
import "@fontsource/open-sans/800-italic.css";
import "@fontsource/roboto-mono/400.css";
import "@fontsource/roboto-mono/500.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fontsource/m-plus-rounded-1c/400.css";


/**
 * Checks all the environement variables are set
 */
const envIsOk =
    typeof process.env.REACT_APP_API_BASE !== "undefined" &&
    typeof process.env.REACT_APP_SR_URI !== "undefined" &&
    typeof process.env.REACT_APP_CMS_URI !== "undefined" &&
    typeof process.env.REACT_APP_MAILCHIMP_AUDIENCE_ID !== "undefined";

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        {envIsOk ? (
            <LoggerProvider clearAfter={100}>
                <Provider store={store}>
                    <SignalRProvider>
                        <Router>
                            <App />
                        </Router>
                    </SignalRProvider>
                </Provider>
            </LoggerProvider>
        ) : (
            <Provider store={store}>
                <Router>
                <ErrorPage
                errorToLog={[
                    "Some or all of the environement variables were undefined %o",
                    {
                        REACT_APP_API_BASE: process.env.REACT_APP_API_BASE,
                        REACT_APP_SR_URL: process.env.REACT_APP_SR_URI,
                        REACT_APP_CMS_URI: process.env.REACT_APP_CMS_URI,
                        REACT_APP_MAILCHIMP_AUDIENCE_ID: process.env.REACT_APP_MAILCHIMP_AUDIENCE_ID
                    },
                ]}
                />
                </Router>
            </Provider>
        )}
    </ThemeProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
