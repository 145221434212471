import { HubConnectionState } from "@microsoft/signalr";
import { QuizQuestion, QuizResponseCountDTO, QuizScoreOverviewDTO, QuizUserScoreDTO, RandomAnswer, ReceiveMessageObject, StateStatus } from "../../types";
import { LikedDemoDTO } from "../../types/apis/models/likedDemos.types";

export enum TimelineStatus {
    Idle = 0,
    ShowQuizQuestion = 1,
    ShowPossibleResponses = 2,
    ShowResult = 3,
    ShowScore = 4,
    EndTour = 5,
    ShowUserAppFeedback = 6,
    EndDemo = 7,
    ShowResultRandom = 8,
    RequestEmail = 9
}

export interface SignalRState {
    readonly status: StateStatus;
    readonly error: string | null;
    readonly connectionStatus: HubConnectionState;
    readonly timelineStatus: TimelineStatus;
    readonly memberCount: number;
    readonly latestMessages: ReceiveMessageObject[];
    readonly currentQuiz: QuizQuestion | null;
    readonly currentScores: QuizUserScoreDTO[] | null;
    readonly currentScoreOverview: QuizScoreOverviewDTO | null;
    readonly currentResponseOverview: QuizResponseCountDTO[] | null;
    readonly currentItemId: number | null;
    readonly likedDemos: LikedDemoDTO[] | null;
    readonly currentMediaItemId: string | null;
    readonly connectedToCashGroup: boolean;
    readonly cachedObjectDto: CachedObjectDto | null;
    readonly currentRandomAnswer: RandomAnswer | null;
}

export interface CachedObjectDto {
    readonly methodName: string;
    readonly args: (string|number|object)[] | null;
}
