import { LikedDemoDTO, LikedDemoPostDTO } from "../../types/apis/models/likedDemos.types";
import { fetchRefreshIntegrated, fetchRefreshIntegratedNoResponse, getBaseUrl } from "../api.utils";

export const postLikedDemo = async (likedDemo: LikedDemoPostDTO) => {
    if(!likedDemo.demoId || !likedDemo.userId){
        return;
    }
    const baseConfigTemplatesURL = await getBaseUrl("likedDemos");
    await fetchRefreshIntegrated<void>(`${baseConfigTemplatesURL}`, { method: "POST", body: JSON.stringify(likedDemo) });
}

export const undoLikedDemo = async (likedDemo: LikedDemoPostDTO) => {
    if(!likedDemo.demoId || !likedDemo.userId){
        return;
    }
    const baseConfigTemplatesURL = await getBaseUrl("likedDemos");
    await fetchRefreshIntegratedNoResponse(`${baseConfigTemplatesURL}`, { method: "DELETE", body: JSON.stringify(likedDemo) });
}

export const getLikedDemosForUser = async (userId: string) : Promise<LikedDemoDTO[]> => {
    const baseConfigTemplatesURL = await getBaseUrl("likedDemos");
    return await fetchRefreshIntegrated(`${baseConfigTemplatesURL}/user/${userId}`);
}