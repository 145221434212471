import { Formik, Field, Form } from "formik";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { registerUser } from "../../store/authentication/authenticationSlice";
import { RegisterDTO, StateStatus } from "../../types";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import forbiddenNicknamesJson from "../../configuration/forbidden-nicknames.json"
import { fetchLocales, setDefaultLocale } from "../../store/localization/localizationSlice";
import { getLocalizedText } from "../../helpers/localizationHelper";
import "./css/ChooseNickname.css";
import LTButton from "../common/UI-Elements/LTButton";
import EarthFooter from "../common/Illustrations/EarthFooter";
import { getGroupExistsById } from "../../store/api-requests/groups";

interface Props {}

const formValues: RegisterDTO = {
    nickname: "",
    groupId: "",
    localeId: "en"
};

const ChooseNickname: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { groupId, mode } = useParams();
    const { status } = useAppSelector((state) => state.auth);
    const { locales, dictionary, defaultLocale } = useAppSelector((state) => state.localization);
    const [forbiddenNicknames, setForbiddenNicknames] = useState<string[]>();
    const [nicknamesRegex, setNicknamesRegex] = useState<string>();
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const isMounted = useRef<boolean>(false);
    const navigate = useNavigate();
    const checkGroupCodeExists = useCallback(async (code: string): Promise<boolean> => {
        let result = false
        if(isMounted.current){
            setIsFetching(true);
        }
        
        try {
            let response = await getGroupExistsById(code);
            result = response.exists;
        } catch (error) {
        }
        if(isMounted.current){
            setIsFetching(false);
        }
        return result;
    }, []);

    useEffect(() => {
        //on mount
        isMounted.current = true;
        //unmount
        return(() => {
            isMounted.current = false;
        })
    },[])

    useEffect(()=>{
        //on mount
        if(groupId){
            checkGroupCodeExists(groupId).then((exists) => {
                if(!exists){
                    navigate("/invalidcode");
                }
            });
        }
        
        const data: string[] = JSON.parse(JSON.stringify(forbiddenNicknamesJson));
        setForbiddenNicknames([...data]);
        initializeLocales();
        formValues.localeId = defaultLocale?.id;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        if(locales.length>0){
            const browserLocales = navigator.languages;
            const localeIds = locales.map((value) => value.id);
            const foundLocale = browserLocales.find(x => localeIds.includes(x));
            if(foundLocale){
                const newDefaultLocale = locales.find(x => x.id === foundLocale);
                if(isMounted.current){
                    dispatch(setDefaultLocale(newDefaultLocale));
                }
                formValues.localeId = newDefaultLocale?.id;
            }
        }
    },[dispatch, locales])

    const initializeLocales = useCallback(async () => {
        await dispatch(fetchLocales());
    },[dispatch]);

    useEffect(() => {
        if(forbiddenNicknames){
            let nicknameRegex = '(';
            forbiddenNicknames.forEach(
                x => nicknameRegex = nicknameRegex.concat(x+'|')
            );
            nicknameRegex = nicknameRegex.slice(0,-1);
            nicknameRegex = nicknameRegex.concat(')');
            if(isMounted.current){
                setNicknamesRegex(nicknameRegex);
            }
        }
    },[forbiddenNicknames]);

    const handleLanguageSelected = useCallback((input: any) => {
        const newLocaleId = input.target.value;
        dispatch(setDefaultLocale({id:newLocaleId}))
        formValues.localeId = newLocaleId;
    },[dispatch]);

    const validationSchema = yup.object({
        nickname: yup
            .string()
            .min(4, getLocalizedText(dictionary,'nicknamelength',defaultLocale.id))
            .required(getLocalizedText(dictionary,'nicknamerequired',defaultLocale.id))
            .test("Nickname not allowed!",getLocalizedText(dictionary,'nicknamenotallowed',defaultLocale.id), (value) => {
                if(!nicknamesRegex){
                    console.error("Nicknames regex missing");
                    return false;
                }
                var regex = new RegExp(nicknamesRegex,'gmi');
                var matchResult = value?.match(regex);
                if(matchResult && matchResult.length > 0){
                    return false;
                }
                return true;
            }),
    });

    const chooseNicknameForm = useMemo(() => {
        return (
            <Formik<RegisterDTO>
            initialValues={formValues}
            validationSchema={validationSchema}
            onSubmit={({ nickname }, { setSubmitting }) => {
                if (groupId != null) {
                    let registerObj: RegisterDTO = { nickname, groupId, localeId:formValues.localeId };
                    const noSpaces = registerObj.nickname.trim().replace(/ /g,'_');
                    registerObj.nickname = noSpaces;
                    dispatch(registerUser(registerObj));
                }
                if(isMounted.current){
                    setSubmitting(false);
                }
            }}
        >
            {({ submitForm, isSubmitting, touched, errors }) => (
                <Form>
                    <div className={`top ${mode !== '1' && "flyInAnimationTop" }`}>
                    <h1>{getLocalizedText(dictionary, 'newuser', defaultLocale.id)}</h1>
                    <p className="labelText"><i className="fa fa-solid fa-masks-theater ltThin"></i>&nbsp;{getLocalizedText(dictionary, 'nickname', defaultLocale.id)}</p>
                    <Field
                        id="nickname"
                        name="nickname"
                        type="text"
                        className="formField"
                        placeholder={getLocalizedText(dictionary, 'nickname', defaultLocale.id)}
                    />
                    {touched.nickname && errors.nickname && <p className="formWarning">{(errors.nickname) || <br/>}</p>}
                    <p className="labelText"><i className="fa fa-solid fa-earth-europe ltThin"></i>&nbsp;{getLocalizedText(dictionary, 'language', defaultLocale.id)}</p>
                    <div className="selectWrapper">
                        <Field as="select" name="localeId" id="localeId" label="Language" className="formField formSelect" value={formValues.localeId} onChange={handleLanguageSelected} autoComplete="off" spellCheck="false">
                        
                        {locales.length > 0 ? locales.map((value, index) => {
                            return <option key={index} value={value?.id}>{value?.name}</option>
                        }) :
                        <>
                            <option value="en">English</option>
                            <option value="fr">Français</option>
                            <option value="nl">Nederlands</option>
                        </> 
                        }
                        </Field>
                    </div>
                    </div>
                    <div className={`bottom ${mode !== '1' && "flyInAnimationBottom" }`}>
                    <LTButton type="submit" className="formField formText" disabled={status === StateStatus.fetching || isSubmitting}>
                        <div className="labelText"><i className="fa fa-solid fa-circle-arrow-right ltThin lightBackground"></i>&nbsp;{getLocalizedText(dictionary, 'continue', defaultLocale?.id)}</div>
                    </LTButton>
                    </div>
                </Form>
            )}
        </Formik>
        );
    },[defaultLocale.id, dictionary, dispatch, groupId, handleLanguageSelected, locales, mode, status, validationSchema]);

    return (
        <div className="chooseNickname">
        {!isFetching ? chooseNicknameForm : <div className="spinner"><i className="fa-solid fa-spinner spinAnimationFast"></i></div>}
        <EarthFooter className={`${mode !== '1' && "flyInAnimationBottom" }`}/>
        </div>
    );
};

export default ChooseNickname;

