import { lazy, Suspense, useEffect, useRef } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "./store";
import {
    getProfileUsingToken,
    setTriedLoggingIn,
} from "./store/authentication/authenticationSlice";
import { StateStatus } from "./types";
import LoadingPage from "./pages/LoadingPage";
import ChooseAvatars from "./components/authentication/ChooseAvatars";
import ChooseNickname from "./components/authentication/ChooseNickname";
import EndTourPage from "./pages/EndTourPage";
import { fetchTranslationOverride } from "./store/localization/localizationSlice";
import CodeRegistration from "./components/authentication/CodeRegistration";
import StartPage from "./pages/StartPage";
import ErrorPage from "./pages/ErrorPage";
import { getLocalizedText } from "./helpers/localizationHelper";
import LTButton from "./components/common/UI-Elements/LTButton";
import nosleep from "nosleep.js";

const AuthRoute = lazy(() => import("./components/common/AuthRoute"));
const PrivateRoute = lazy(() => import("./components/common/PrivateRoute"));
const RegisterPage = lazy(() => import("./pages/authentication/RegisterPage"));
const RoomPage = lazy(() => import("./pages/TourPage"));

function App() {
    const { user, triedLoggingIn, status } = useAppSelector(
        (state) => state.auth
    );

    const { dictionary, defaultLocale } = useAppSelector((state) => state.localization);
    
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const noSleep = useRef<nosleep>(new nosleep());
    
    useEffect(() => {
        if ("wakeLock" in navigator) {
            noSleep.current.enable();
        }
        document.addEventListener("visibilitychange", () => {
            if (!noSleep.current.isEnabled && "wakeLock" in navigator) {
                noSleep.current.enable();
            }
        });

        document.addEventListener("touchend", () => {
            if (!noSleep.current.isEnabled) {
                noSleep.current.enable();
            }
        });
    },[]);

    useEffect(() => {
        dispatch(fetchTranslationOverride());
    },[dispatch]);

    useEffect(() => {
        if (!user) {
            if (!triedLoggingIn) {
                dispatch(getProfileUsingToken());
                dispatch(setTriedLoggingIn(true));
            }
        }
    }, [dispatch, triedLoggingIn, user]);

    return (
        <Suspense fallback={<LoadingPage />}>
            {status === StateStatus.fetching && user == null ? (
                <LoadingPage />
            ) : (
                <Routes>
                    <Route
                        path="/room"
                        element={
                            <PrivateRoute>
                                {user?.groupId ? <RoomPage /> : <></>}
                            </PrivateRoute>
                        }
                    />
                    <Route path="/register/*">
                        <Route
                            path=":groupId"
                            element={<Navigate to="choose-nickname" />}
                        />
                        <Route
                            path=":groupId/choose-nickname"
                            element={<RegisterPage><ChooseNickname/></RegisterPage>}
                        />
                        <Route
                            path=":groupId/choose-nickname/:mode"
                            element={<RegisterPage><ChooseNickname/></RegisterPage>}
                        />
                        <Route
                            path=":groupId/choose-avatar"
                            element={
                                <RegisterPage>
                                    <ChooseAvatars />
                                </RegisterPage>
                            }
                        />
                        <Route
                            path="*"
                            element={
                                <AuthRoute>
                                    <RegisterPage>
                                        <CodeRegistration/>
                                    </RegisterPage> 
                                </AuthRoute>
                            }
                        />
                    </Route>
                    <Route
                            path="/join"
                            element={<StartPage/>}
                        >
                            <Route
                            path=":groupId"
                            element={<StartPage/>}/>
                        <Route
                            path=":groupId/choose-nickname"
                            element={<StartPage/>}/>
                    </Route>
                    <Route path="/invalidcode" element={<ErrorPage errorText={getLocalizedText(dictionary, "invalidcode", defaultLocale.id)}>
                        <LTButton className="lonelyButton" onClick={() => navigate("/register")}>{getLocalizedText(dictionary, "retry", defaultLocale.id)}</LTButton>
                    </ErrorPage>}></Route>
                    <Route path="/end" element={<EndTourPage />}></Route>
                    <Route path="/" element={<Navigate to="/join" />} />
                    <Route path="/:any" element={<ErrorPage/>} />
                </Routes>
            )}
        </Suspense>
    );
}

export default App;
