import { UserDTO } from "../../types/apis";
import { fetchRefreshIntegrated, getBaseUrl } from "../api.utils";

export const getUserByToken = async (): Promise<UserDTO> =>
	await fetchRefreshIntegrated<UserDTO>(`${await getBaseUrl("users")}/me`);

export const patchUserAvatar = async (newAvatarId: string): Promise<UserDTO> =>
	await fetchRefreshIntegrated<UserDTO>(`${await getBaseUrl("users")}`, { method: "PATCH", body: JSON.stringify({ avatarId: newAvatarId }) });

export const patchUserDemo = async (id: string, newDemoId: string): Promise<UserDTO> =>
	await fetchRefreshIntegrated<UserDTO>(`${await getBaseUrl("users")}/${id}`, { method: "PATCH", body: JSON.stringify({ newDemoId: newDemoId }) });
