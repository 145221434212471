import { FC, memo } from "react";
import LTLogo from "./LTLogo";
import './css/LTLogoFooter.css'
interface Props {

}

const LTLogoFooter : FC<Props> = () => {
    return (<div className="footerLogo">
        <LTLogo/>
    </div>);
}

export default memo(LTLogoFooter);